import { React, useState, useEffect, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import DoctorServices from '../services/DoctorServices';
import { Modal, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from '../utils/toast';
import Loading from '../components/preloader/Loading';
import { toTitleCase, AdminDetails } from '../utils/functions'
import DataTable from '../components/DataTable'


function SubAccount() {

  const columns = useMemo(
    () => [
      {
        Header: "Account#",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "title",
      },
      {
        Header: "Contact#",
        accessor: "contact_number",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Crated at",
        accessor: "created_at",
      },
      {
        Header: "Total Orders",
        accessor: "total_orders",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return toTitleCase(row.original.status)
        }
      },
      {
        Header: " ",
        accessor: " ",
        Cell: ({ value, row }) => {
          return (
            <><button type="button" className="btn btn-warning btn-sm" onClick={() => editAccount(row.original.id)} ><i className="ti-pencil"></i></button>&nbsp;&nbsp;
              <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteAccount(row.original.id)} ><i className="ti-trash"></i></button></>
          )
        }
      }
    ]);

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  const [showToggle, setShowToggle] = useState(false)
  const [accountList, setAccountList] = useState([])
  const [tempData, setTempData] = useState([])
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editMode, setEditMode] = useState(false)
  const [reload, setReload] = useState(false)

  const [accountId, setAccountId] = useState(0)

  let navigate = useNavigate();

  function fetchData() {
    setLoading(true)
    DoctorServices.getAllSubAccounts(adminInfo.id)
      .then((response) => {
        setAccountList(response.data)
        setTempData(response.data)
        setLoading(false);
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setLoading(false);
      });
  }

  const onSearch = (e) => {
    let t = [];

    if (e.target.value.length == 0) {
      setAccountList(tempData)
    } else {

      tempData.map((account) => {
        if ((account.post_title.toLowerCase().includes(e.target.value.toLowerCase()) || (account.email.toLowerCase().includes(e.target.value.toLowerCase())))) {
          t.push(account)
        }
      })
      setAccountList(t)
    }
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const reset = () => {
    setValue('addAccountFullName')
    setValue('addAccountEmail')
    setValue('addAccountContactNumber')
    setValue('addAccountGender')
    setValue('addAccountStatus')
    setValue('addAccountPassword')
    setAccountId(0)
  }

  const addAccount = () => {
    reset()
    setShowToggle(true)
    setEditMode(false)
  }


  const closeAddDoctor = () => {
    setShowToggle(false)
    setEditLoading(false)
  }

  const onSubmit = data => {

    const postData = {
      'title': data.addAccountFullName,
      'email': data.addAccountEmail,
      'contact_number': data.addAccountContactNumber,
      'gender': data.addAccountGender,
      'password': data.addAccountPassword,
      'parent_id': adminInfo.id
    }
    setProcessing(true)
    DoctorServices.createSubAccount(postData)
      .then((response) => {
        if (response.success) {
          notifySuccess('Action completed successfully!')
          setProcessing(false)
          setShowToggle(false)
          setReload(!reload)
          reset()
        } else {
          notifyError(response.message);
          setProcessing(false);
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setProcessing(false)
      });

  }


  const editAccount = (id) => {

    setShowToggle(true)
    setEditMode(true)
    setEditLoading(true)

    DoctorServices.getDoctorById(id, { 'doctor_id': id })
      .then((response) => {
        if (response.success) {
          setEditLoading(false)
          if (response.data) {

            const d = response.data
            setValue('addAccountFullName', d.title)
            setValue('addAccountEmail', d.email)
            setValue('addAccountContactNumber', d.contact_number)
            setValue('addAccountGender', d.gender)
            setValue('addAccountStatus', d.status)
            setAccountId(d.id)
          }

        } else {
          notifyError('can not create docotr, please try again!');
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
      });

  }


  const updateAccount = (data) => {

    const postData = {
      'title': data.addAccountFullName,
      'email': data.addAccountEmail,
      'contact_number': data.addAccountContactNumber,
      'gender': data.addAccountGender,
      'status': data.addAccountStatus,
      'password': data.addAccountPassword
    }
    setProcessing(true)
    DoctorServices.updateAccount(accountId, postData)
      .then((response) => {
        if (response.success) {
         
          notifySuccess('Action completed successfully!')
          setShowToggle(false)
          setEditMode(false)
          setEditLoading(false)
          setAccountId(0)
          setReload(!reload)
        } else {
        
          notifyError('can not create docotr, please try again!');
          setProcessing(false);
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setProcessing(false)
      });

  }

  const deleteAccount = (id) => {

    if (!window.confirm('are you sure want to delete?')) {
      return;
    }

    setProcessing(true)
    DoctorServices.deleteAccount(id, {'parent_id': adminInfo.id})
      .then((response) => {
        if (response.success) {
          notifySuccess('Action completed successfully!')
          setProcessing(false)
          setShowToggle(false)
          setReload(!reload)
        } else {
          notifyError(response.message);
          setProcessing(false);
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setProcessing(false)
      });

  }

  const iStyle = {
    marginRight: "10px"
  }

  useEffect(() => {
    //load_data()
  }, [reload]);


  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
                <h4 className="card-title">Sub Accounts</h4>
              </div>

              <div className="col-lg-6">
                <button type="button" className="btn btn-success" style={{ float: "right" }} >
                  <i className="ti-download"></i>
                </button>
                <button type="button" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} onClick={addAccount}><i className="ti-plus"></i>&nbsp;Add Sub Account</button>
                <input type="text" className="form-control-lg" style={{ border: "1px solid #DFDFDF", float: "right", marginRight: "10px" }} placeholder="Search account...." onChange={onSearch} />
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={accountList}
                fetchData={fetchData}
                loading={loading}
                pageRecords={accountList.length}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showToggle} size="lg">
        <div className="col-lg-12 grid-margin">
          <div className="card111">
            <div className="card-body111">
              {editLoading ? <Loading loading={editLoading} /> : (
                <form className="form-sample" onSubmit={handleSubmit(editMode ? updateAccount : onSubmit)}>
                  <Modal.Header><h4 className="card-title">Add Sub Account</h4></Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Email <span className="required">*</span></label>
                          <div className="col-sm-9">

                            {editMode ? (
                              <input type="email" className="form-control form-control-lg" disabled name="addAccountEmail" {...register("addAccountEmail", {
                                required: "Email is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Please enter valid email" }
                              })} />
                            ) : (
                                <input type="email" className="form-control form-control-lg" name="addAccountEmail" {...register("addAccountEmail", {
                                  required: "Email is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Please enter valid email" }
                                })} />
                              )
                            }
                            <ErrorMessage
                              errors={errors}
                              name="addAccountEmail"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />

                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Password <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="password" className="form-control form-control-lg" name="addAccountPassword" {...register("addAccountPassword", { required: editMode ? false : "Password is required" })} />
                            <ErrorMessage
                              errors={errors}
                              name="addAccountPassword"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Full Name <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control-lg" name="addAccountFullName" {...register("addAccountFullName", { required: "Please enter full name." })} />
                            <ErrorMessage
                              errors={errors}
                              name="addAccountFullName"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Contact# <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control-lg" name="addAccountContactNumber" {...register("addAccountContactNumber", { required: "Please enter contact number." })} />
                            <ErrorMessage
                              errors={errors}
                              name="addAccountContactNumber"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Gender <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <select className="form-control form-control-lg" name="addAccountGender" {...register("addAccountGender")}>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {editMode ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Status</label>
                            <div className="col-sm-9">
                              <select className="form-control form-control-lg" name="addAccountStatus" {...register("addAccountStatus")}>
                                <option value="1">Active</option>
                                <option value="0">Deactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : ""}
                    </div>

                  </Modal.Body>
                  <Modal.Footer>
                    <input type="submit" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} value={editMode ? "Update Doctor" : "Create Doctor"} />
                    <button type="button" className="btn btn-danger" style={{ float: "right", "marginRight": "10px" }} onClick={closeAddDoctor}>Cancel</button>
                  </Modal.Footer>
                </form>)}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default SubAccount