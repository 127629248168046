export const Arabic = {
    title: 'لوحة Picpax',
    version: 'الإصدار 1.0.0',
    login: {
        username: 'اسم المستخدم',
        password: 'كلمة المرور', 
        heading: "مرحبًا! هيا بنا نبدأ",
        signin: "تسجيل الدخول",
        signingin: "تسجيل الدخول...",
        errors: {
            usernameError: 'الرجاء إدخال اسم المستخدم',
            passwordError: 'الرجاء إدخال كلمة المرور',
        }
    }
}