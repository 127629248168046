import React, {createContext, useState} from 'react';
import { English } from '../i18n/en';
import { Arabic } from '../i18n/ar';


export const TranslationContext = createContext();

export const TranslationProvider = ({children}) => {

    const [language, setLanguage] = useState(English)

    function changeLanguage(lang) {

        switch(lang){
            case 'en': 
                setLanguage(English)
            break;
            default: 
                setLanguage(Arabic)
            break;
        }

    }

    return (
        <TranslationContext.Provider value = {{
            language,
            changeLanguage
        }}>
            {children}
        </TranslationContext.Provider>
    )

}