import React from 'react';
import ReactDOM from 'react-dom';


import "./assets/css/feather.css"
import "./assets/css/materialdesignicons.min.css"
import "./assets/css/themify-icons.css"
import "./assets/css/typicons.css"
import './assets/css/simple-line-icons.css'
import './assets/css/vendor.bundle.base.css'
import './assets/css/style.css';
import { AdminProvider } from './context/AdminContext';
import { GlobalProvider } from './context/AdminContext'


import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <GlobalProvider>
    <AdminProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AdminProvider>
  </GlobalProvider>,
  document.getElementById('root')
);

reportWebVitals();
