import { React, useState, useEffect, useMemo, useContext } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import DoctorServices from '../services/DoctorServices';
import { Modal, Button } from "react-bootstrap"
import countryData from '../assets/data/countries.json'
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from '../utils/toast';
import Loading from '../components/preloader/Loading';
import { toTitleCase, AdminDetails } from '../utils/functions'
import DataTable from '../components/DataTable'
import {TranslationContext} from '../context/TranslationContext'

function Doctors() {

  const [showToggle, setShowToggle] = useState(false)
  const [doctorList, setDoctorList] = useState([])
  const [tempData, setTempData] = useState([])
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editMode, setEditMode] = useState(false)
  const [doctorId, setDoctorId] = useState(0)
  const [reload, setReload] = useState(false)
  const { language } = useContext(TranslationContext);

  

  const columns = useMemo(
    () => [

      {
        Header: language.doctors.lDoctorNumber,
        accessor: "id",
      },
      {
        Header: language.doctors.lName,
        accessor: "title",
      },
      {
        Header: language.doctors.lClinic,
        accessor: "clinic_name",
      },
      {
        Header: language.doctors.lContactNumber,
        accessor: "contact_number",
      },
      {
        Header: language.doctors.lCreatedAt,
        accessor: "created_at",
      },
      {
        Header: language.doctors.lOrderAccount,
        accessor: "total_orders",
      },
      {
        Header: language.doctors.lStatus,
        accessor: "status",
        Cell: ({ value, row }) => (row.original.status == "Active" ? "Active" : "In-Active")
      },
      {
        Header: "",
        accessor: "buttons",
        Cell: ({ value, row }) => (<button type="button" className="btn btn-warning btn-sm" onClick={() => editDoctor(row.original.id)} ><i className="ti-pencil"></i></button>)
      }
    ]);


  let navigate = useNavigate();

  function fetchData() {
    setLoading(true)
    DoctorServices.getAllDoctors()
      .then((response) => {
     
        setDoctorList(response)
        setTempData(response)
        setLoading(false);
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setLoading(false);
      });
  }

  const onSearch = (e) => {
    let t = [];

    if (e.target.value.length == 0) {
      setDoctorList(tempData)
    } else {


      tempData.map((doctor) => {
        if ((doctor.clinic_name.toLowerCase().includes(e.target.value.toLowerCase()) || doctor.title.toLowerCase().includes(e.target.value.toLowerCase()) || (doctor.contact_number.toLowerCase().includes(e.target.value.toLowerCase())))) {
          t.push(doctor)
        }
      })
      setDoctorList(t)
    }
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const reset = () => {
    setValue('addDoctorFullName')
    setValue('addDoctorEmail')
    setValue('addDoctorContactNumber')
    setValue('addDoctorClinicName')
    setValue('addDoctorGender')
    setValue('addDoctorAddress')
    setValue('addDoctorEmirates')
    setValue('addDoctorStatus')
    setDoctorId(0)
  }

  const onSubmit = data => {
    const postData = {
      'title': data.addDoctorFullName,
      'email': data.addDoctorEmail,
      'contact_number': data.addDoctorContactNumber,
      'clinic_name': data.addDoctorClinicName,
      'gender': data.addDoctorGender,
      'address': data.addDoctorAddress,
      'emirates': data.addDoctorEmirates,
      'password': data.addDoctorPassword
    }
    setProcessing(true)
    DoctorServices.createDoctor(postData)
      .then((response) => {
        if (response.success) {
          notifySuccess('Action completed successfully!')
          setProcessing(false)
          setShowToggle(false)
          setReload(!reload)
        } else {
          notifyError(response.message);
          setProcessing(false);
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setProcessing(false)
      });
  }

  const addDoctor = () => {
    reset()
    setShowToggle(true)
    setEditMode(false)
  }

  //onEdit functions
  const editDoctor = (id) => {

    setShowToggle(true)
    setEditMode(true)
    setEditLoading(true)

    DoctorServices.getDoctorById(id, { 'doctor_id': id })
      .then((response) => {
        if (response.success) {
          setEditLoading(false)
          if (response.data) {

            const d = response.data
            setValue('addDoctorFullName', d.title)
            setValue('addDoctorEmail', d.email)
            setValue('addDoctorContactNumber', d.contact_number)
            setValue('addDoctorClinicName', d.clinic_name)
            setValue('addDoctorGender', d.gender)
            setValue('addDoctorAddress', d.address)
            setValue('addDoctorEmirates', d.emirates)
            setValue('addDoctorStatus', d.status)
            setDoctorId(d.id)

          }

        } else {
          notifyError('can not create docotr, please try again!');
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
      });

  }

  const updateDoctor = (data) => {


    const postData = {
      'title': data.addDoctorFullName,
      'email': data.addDoctorEmail,
      'contact_number': data.addDoctorContactNumber,
      'clinic_name': data.addDoctorClinicName,
      'gender': data.addDoctorGender,
      'address': data.addDoctorAddress,
      'emirates': data.addDoctorEmirates,
      'status': data.addDoctorStatus,
      'password': data.addDoctorPassword
    }
    setProcessing(true)
    DoctorServices.updateDoctor(doctorId, postData)
      .then((response) => {
        if (response.success) {
          console.log(postData)
         
          notifySuccess('Action completed successfully!')
          setShowToggle(false)
          setEditMode(false)
          setEditLoading(false)
          setDoctorId(0)
          setReload(!reload)
        } else {
          console.log(response)
          notifyError('can not create docotr, please try again!');
          setProcessing(false);
        }

      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setProcessing(false)
      });

  }


  const closeAddDoctor = () => {
    setShowToggle(false)
    setEditLoading(false)
  }

  function populateCountriesDropDown() {
    const countryList = [];
    countryData.map((country, i) => {
      countryList.push(<option key={country.value} value={country.text}>{country.text}</option>);
    })
    return countryList
  }

  const iStyle = {
    marginRight: "10px"
  }

  useEffect(() => {
    clearErrors('addDoctorEmail');
    clearErrors('addDoctorPassword');
    clearErrors('addDoctorFullName');
    clearErrors('addDoctorClinicName');
    clearErrors('addDoctorContactNumber');
    clearErrors('addDoctorAddress');
    //load_data()
  }, [reload]);


  return (

    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
                <h4 className="card-title">Doctors</h4>
              </div>

              <div className="col-lg-6">
                <button type="button" className="btn btn-success" style={{ float: "right" }} onClick={() => window.open('http://picpaxnutrition.com/api/doctors/export', '_blank')} >
                  <i className="ti-download"></i>
                </button>
                <button type="button" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} onClick={addDoctor}><i className="ti-plus"></i>&nbsp;{language.doctors.addDocotr}</button>
                <input type="text" className="form-control-lg" style={{ border: "1px solid #DFDFDF", float: "right", marginRight: "10px" }} placeholder= {language.doctors.searchDoctor} onChange={onSearch} />
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={doctorList}
                fetchData={fetchData}
                loading={loading}
                pageRecords={doctorList.length}
                reload = {reload}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showToggle} size="lg">
        <div className="col-lg-12 grid-margin">
          <div className="card111">
            <div className="card-body111">
              {editLoading ? <Loading loading={editLoading} /> : (
                <form className="form-sample" onSubmit={handleSubmit(editMode ? updateDoctor : onSubmit)}>
                  <Modal.Header><h4 className="card-title">{language.doctors.addDocotr}</h4></Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.email} <span className="required">*</span></label>
                          <div className="col-sm-9">

                            {editMode ? (
                              <input type="email" className="form-control form-control-lg"
                              
                              
                              name="addDoctorEmail" {...register("addDoctorEmail", {
                                required: "Email is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: language.doctors.errors.email }
                              })} />
                            ) : (
                                <input type="email" className="form-control form-control-lg" name="addDoctorEmail" {...register("addDoctorEmail", {
                                  required: "Email is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: language.doctors.errors.email }
                                })} />
                              )
                            }
                            <ErrorMessage
                              errors={errors}
                              name="addDoctorEmail"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />

                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.password} <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="password" className="form-control form-control-lg" name="addDoctorPassword" {...register("addDoctorPassword", { required: false })} />
                            <ErrorMessage
                              errors={errors}
                              name="addDoctorPassword"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.fullName} <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control-lg" name="addDoctorFullName" {...register("addDoctorFullName", { required: language.doctors.errors.fullName })} />
                            <ErrorMessage
                              errors={errors}
                              name="addDoctorFullName"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.clinicName} <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control-lg" name="addDoctorClinicName" {...register("addDoctorClinicName", { required: language.doctors.errors.clinicName })} />
                            <ErrorMessage
                              errors={errors}
                              name="addDoctorClinicName"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.gender} <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <select className="form-control form-control-lg" name="addDoctorGender" {...register("addDoctorGender")}>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.contact} <span className="required">*</span></label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control-lg" name="addDoctorContactNumber" {...register("addDoctorContactNumber", { required: language.doctors.errors.contactNumber })} />
                            <ErrorMessage
                              errors={errors}
                              name="addDoctorContactNumber"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.address}</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control-lg" name="addDoctorAddress" {...register("addDoctorAddress", { required: language.doctors.errors.address })} />
                            <ErrorMessage
                              errors={errors}
                              name="addDoctorAddress"
                              render={({ message }) => <p className="form-error">{message}</p>}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{language.doctors.emirates}</label>
                          <div className="col-sm-9">
                            <select className="form-control form-control-lg" name="addDoctorEmirates" {...register("addDoctorEmirates")}>
                              <option value="abu_dhabi">{language.doctors.abuDhabi}</option>
                              <option value="dubai">{language.doctors.dubai}</option>
                              <option value="sharjah">{language.doctors.sharjah}</option>
                              <option value="ras_al_khaimah">{language.doctors.rak}</option>
                              <option value="fujairah">{language.doctors.fujairah}</option>
                              <option value="umm_al_quwaim">{language.doctors.umm}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {editMode ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{language.doctors.status}</label>
                            <div className="col-sm-9">
                              <select className="form-control form-control-lg" name="addDoctorStatus" {...register("addDoctorStatus")}>
                                <option value="1">Active</option>
                                <option value="0">Deactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : ""}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <input type="submit" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} value={editMode ? language.doctors.updateDoctor : language.doctors.createDoctor} />
                      <button type="button" className="btn btn-danger" style={{ float: "right", "marginRight": "10px" }} onClick={closeAddDoctor}>{language.doctors.cancel}</button>
                  </Modal.Footer>
                </form>)}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default Doctors