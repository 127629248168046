import { React, Suspense, lazy, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import TopBar from '../components/header/TopBar'
import Sidebar from '../components/sidebar/Sidebar'
import pageRoutes from '../routes';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Main from './Main';
import Cookies from 'js-cookie';
import { useState, useContext } from 'react'
import { AdminContext, GlobalProvider, GlobalContext } from '../context/AdminContext';
import { ErrorMessage } from '@hookform/error-message';
import { notifySuccess, notifyError } from '../utils/toast';
import {TranslationContext} from '../context/TranslationContext';
import requests from '../services/httpService';


const NotFound = lazy(() => import('../pages/404'));

function Layout() {
  let location = useLocation();
  const { state } = useContext(AdminContext);
  const { openProfile, setOpenProfile } = useContext(GlobalContext);
  const { adminInfo } = state;
  const [editProfileMode, setEditProfileMode] = useState(false)
  const { language } = useContext(TranslationContext);


  const [ openMenu, setOpenMenu ] = useState(false);
  

  const letOpenIt = () => {
    
    if(openMenu){
      setOpenMenu(false)
    }else {
      setOpenMenu(true)
    }
    
  }

  const onSubmit = (data) => {
    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();

    formData.append("file", fileInput.files[0]);
    formData.append("id", adminInfo.id);

    requests.post('/api/profile_picture', formData )
    .then(function(response) {
      adminInfo.profile_picture = response;
      const profile = {
        "id":adminInfo.id,
        "profile_picture": response,
        "title":adminInfo.title,
        "email":adminInfo.email,
        "contact_number":adminInfo.contact_number,
        "clinic_name":adminInfo.clinic_name,
        "gender":adminInfo.gender,
        "address":adminInfo.address,
        "emirates":adminInfo.emirates,
        "parent_id":adminInfo.parent_id,
        "status":adminInfo.status,
        "panel_admin":adminInfo.panel_admin,
        "logo":adminInfo.logo,
        "created_at":adminInfo.created_at,
        "updated_at":adminInfo.updated_at

      };
      Cookies.set('adminInfo',JSON.stringify(profile) , { expires: 7 });

     });
 
    return false;
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const closeProfilePage = () => {
    setOpenProfile(false)
  }

  useEffect(() => {
    setValue("addProfileEmail", adminInfo.email)
    setValue("addProfileFullName", adminInfo.title)
    setValue("addProfileClinicName", adminInfo.clinic_name)
    setValue("addProfileContactNumber", adminInfo.contact_number)
    setValue("addProfileGender", adminInfo.gender)
    setValue("addProfileAddress", adminInfo.address)
    setValue("addProfileEmirates", adminInfo.emirates)
    setValue("addProfileImage",adminInfo.profile_picture)
  }, [location]);
  return (
    <>
      <div className="container-scroller">
        <TopBar showOpenMenu = {letOpenIt} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar openMenu = {openMenu} showOpenMenu = {setOpenMenu}  />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">

                <Routes>
                  {pageRoutes.map((route, i) => {
                    return (route.component && (route.admin && adminInfo.panel_admin == "1") || !route.admin) ? (
                      <Route
                        key={i}
                        path={`${route.path}`}
                        element={<route.component />}
                      />
                    ) : null;
                  })}
                  <Route element={NotFound} />
                </Routes>
              </div>
            </div>
            <footer className="footer">
              <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">{language.version}</span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">{language.copyright}</span>
              </div>
            </footer>
          </div>
        </div>
      </div>
      <Modal show={openProfile} size="lg">
        <div className="col-lg-12 grid-margin">
          <div className="card111 aa">
            <div className="card-body111">

              <form className="form-sample" onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header><h4 className="card-title">{language.doctors.addDoctor}</h4></Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.email} <span className="required">*</span></label>
                        <div className="col-sm-9">

                          <input type="email" className="form-control form-control-lg" disabled name="Email" {...register("addProfileEmail", {
                            required: "Email is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Please enter valid email" }
                          })} />
                          <ErrorMessage
                            errors={errors}
                            name="addProfileEmail"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.password} <span className="required">*</span></label>
                        <div className="col-sm-9">
                          <input type="password" className="form-control form-control-lg" name="addProfilePassword" {...register("addProfilePassword", { required: false })} />
                          <ErrorMessage
                            errors={errors}
                            name="addProfilePassword"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.fullName} <span className="required">*</span></label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control form-control-lg" name="addProfileFullName" {...register("addProfileFullName", { required: "Please enter full name." })} />
                          <ErrorMessage
                            errors={errors}
                            name="addProfileFullName"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.clinicName} <span className="required">*</span></label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control form-control-lg" name="addProfileClinicName" {...register("addProfileClinicName", { required: "Please enter clinic name." })} />
                          <ErrorMessage
                            errors={errors}
                            name="addProfileClinicName"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.gender} <span className="required">*</span></label>
                        <div className="col-sm-9">
                          <select className="form-control form-control-lg" name="addProfileGender" {...register("addProfileGender")}>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.contact} <span className="required">*</span></label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control form-control-lg" name="addProfileContactNumber" {...register("addProfileContactNumber", { required: "Please enter contact number." })} />
                          <ErrorMessage
                            errors={errors}
                            name="addProfileContactNumber"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.address}</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control form-control-lg" name="addProfileAddress" {...register("addProfileAddress", { required: "Please enter clinic address." })} />
                          <ErrorMessage
                            errors={errors}
                            name="addProfileAddress"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.emirates}</label>
                        <div className="col-sm-9">
                          <select className="form-control form-control-lg" name="addProfileEmirates" {...register("addProfileEmirates")}>
                        <option value="abu_dhabi">{language.doctors.abuDhabi}</option>
                            <option value="dubai">{language.doctors.dubai}</option>
                            <option value="sharjah">{language.doctors.sharjah}</option>
                            <option value="ras_al_khaimah">{language.doctors.rak}</option>
                            <option value="fujairah">{language.doctors.fujairah}</option>
                            <option value="umm_al_quwaim">{language.doctors.umm}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{language.doctors.profile}</label>
                        <div className="col-sm-9">
                          <input type="file"  id="fileInput" alt="file" name="image" {...register("addProfileImage", { required: "Please enter clinic address." })}/>
                          <ErrorMessage
                            errors={errors}
                            name="addProfileimage"
                            render={({ message }) => <p className="form-error">{message}</p>}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      
                    </div>

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <input type="submit" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} value= {language.doctors.updateProfile} />
                  <button type="button" className="btn btn-danger" style={{ float: "right", "marginRight": "10px" }} onClick={closeProfilePage} >{language.doctors.cancel}</button>
                </Modal.Footer>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default Layout