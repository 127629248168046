import requests from './httpService';
import Cookies from 'js-cookie';
const PatientmoduleServices = {
    
  getAllPatient(body) {
    const adminInfo = JSON.parse(Cookies.get('adminInfo'));
    return requests.post("/api/patientmodule?id="+adminInfo.id, body);
  },
};

export default PatientmoduleServices;
