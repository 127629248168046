import React, { useContext, useState } from 'react';
import logo from '../assets/images/login.png';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import DoctorServices from '../services/DoctorServices';
import { notifySuccess, notifyError } from '../utils/toast';
import {TranslationContext} from '../context/TranslationContext'


function Login() {

    const [loading, setLoading] = useState(false);
    const { dispatch } = useContext(AdminContext);
    const { language, changeLanguage } = useContext(TranslationContext);

    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = data => {
        setLoading(true)
        DoctorServices.loginDoctor({ email: data.userName, password: data.userPassword })
            .then((response) => {
                if (response.success) {
                    notifySuccess('Login Success!');
                    dispatch({ type: 'USER_LOGIN', payload: response.data });
                    Cookies.set('adminInfo', JSON.stringify(response.data));
                    setTimeout(() => {
                        navigate('/', { replace: true });
                    }, 1000);

                } else {
                    notifyError('Invalid credentials!');
                    setLoading(false);
                }

            })
            .catch((err) => {
                notifyError(err ? err.response.data.message : err.message);
                setLoading(false);
            });
    }

    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="col-lg-4 mx-auto">
                            
                                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <select style = {{float: 'right', textAlign: 'left'}} onChange = {(val) => changeLanguage(val.target.value)}>
                                    <option value = "en">English </option>
                                    <option value = "ar">العربية </option>
                                </select>
                                    <div className="brand-logo">
                                        <img src={logo} alt="logo" />
                                    </div>
                            <h4>{language.login.heading}</h4>

                                    <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg" name="userName" placeholder= {language.login.username} {...register("userName", { required: language.login.errors.usernameError })} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="userName"
                                                render={({ message }) => <p className="form-error">{message}</p>}
                                            />

                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control form-control-lg" name="userPassword" placeholder = {language.login.password} {...register("userPassword", { required: language.login.errors.passwordError })} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="userPassword"
                                                render={({ message }) => <p className="form-error">{message}</p>}
                                            />

                                        </div>
                                        <div className="mt-3">
                                            <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" value={loading ? language.login.signingin : language.login.signin} />
                                        </div>
                                        <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                            </div>
                                             {language.version}
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Login