export const English = {
    title: 'Picpax Panel',
    version: 'Version 1.0.0',
    copyright: 'Copyright © ' + new Date().getFullYear() + ' PicPax. All rights reserved.',
    pending: 'Pending',
    active: 'Active',
    blocked: 'Blocked',
    noRecordFound: 'No record found',
    login: {
        username: 'Username',
        password: 'Password', 
        heading: "Hello! let's get started",
        signin: "Signin",
        signingin: "Signing in...",
        errors: {
            usernameError: 'Please enter your user name',
            passwordError: 'Please enter password',
        }
    }, 
    menu: {
        dashboard: 'Dashboard',
        doctors: 'Doctors',
        orders: 'Orders',
        products: 'Products',
        contactus: 'Contact us',
        patient: 'Patients'
    },
    dashboard: {
        totalSale: 'Total Sale',
        totalOrders: 'Total Orders',
        newCustomers: 'New Customers',
        cancelation: 'Cancelation',
        monthlySale: 'Monthly Sale'
    },
    header: {
        searchHere: 'Search here',
        myProfile: 'My profile',
        subAccount: 'Sub account',
        notification: 'Notification',
        signout: 'Sign out'
    },
    doctors: {
        addDocotr: 'Add doctor',
        email: 'Email',
        profile:'Profile',
        password: 'Password',
        fullName: 'Full name',
        clinicName: 'Clinic name',
        gender: 'Gender',
        contact: 'Contact#1',
        address: 'Address',
        emirates: 'Emirates',
        abuDhabi: 'Abu dhabi',
        dubai: 'Dubai',
        sharjah: 'Sharjah',
        rak: 'Ras al khaimah',
        fujairah: 'Fujairah',
        umm: 'Umm al quwaim',
        status: 'Status',
        updateProfile: 'Update profile',
        cancel: 'Cancel',
        createDoctor: 'Create doctor',
        updateDoctor: 'Update doctor',
        searchDoctor: 'Search doctor',
        lDoctorNumber: 'Doctor#',
        lName: 'Name',
        lClinic: 'Clinic',
        lContactNumber: 'Contact#',
        lCreatedAt: 'Created at',
        lOrderAccount: 'Orders / Accounts',
        lStatus: 'Status',
        errors: {
            email: 'Please enter value email',
            fullName: 'Please enter full name',
            clinicName: 'Please enter clinic name',
            contactNumber: 'Please enter contact number',
            address: 'Please enter clinic address',
        }
    },
    orders: {
        order: 'Orders',
        createOrder: 'Create order',
        searchOrder: 'Search order',
        lOrderNumber: 'Order#',
        lName: 'Name',
        lEmail: 'Email', 
        lTotal: 'Total',
        lCity: 'City',
        lCreator: 'Creator',
        lStatus: 'Status',
        cancel: 'Cancel',
        items: 'Items',
        creating: 'Creating...',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        contactNumber: 'Contact#',
        weight: 'Weight (KG)',
        height: 'Height (CM)',
        DOB: 'Date of birth',
        emirates: 'Emirates',
        abuDhabi: 'Abu dhabi',
        dubai: 'Dubai',
        sharjah: 'Sharjah',
        rak: 'Ras al khaimah',
        aj:'Ajman',
        fujairah: 'Fujairah',
        umm: 'Umm al quwaim',
        addItems: 'Add items',
        errors: {
            email: 'Please enter value email',
            firstName: 'Please enter first name',
            lastName: 'Please enter last name',
            contactNumber: 'Please enter contact number',
            address: 'Please enter clinic address',

        },
        products: {
            product: 'Product',
            sku: 'SKU',
            quantity: 'Quantity',
            dosage: 'Dosage',
            remove: 'Remove'
        }
    },
    products: {
        products: 'Products',
        product: 'Name',
        sku: 'SKU',
        quantity: 'Bulk',
        dosage: 'Type',
    },
    notifications: {
        notifications: 'Notifications',
        searchNotification: 'Search notification',
        notificastion: 'Notification',
        type: 'Type',
        createdAt: 'Created at',
        status: 'Status',
    }


}