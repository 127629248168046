import { React, useState, useEffect, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import ProductServices from '../services/ProductServices';
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from '../utils/toast';
import { Modal, Button } from "react-bootstrap"
import Loading from '../components/preloader/Loading';
import { toTitleCase, isBulk } from '../utils/functions'
import DataTable from '../components/DataTable'
import { TranslationContext } from '../context/TranslationContext'


function Products() {
  const [getProductData, setProductData] = useState(false);
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const [productsList, setProductsList] = useState([])
  const [tempData, setTempData] = useState([])
  const [loading, setLoading] = useState(false);
  const { language } = useContext(TranslationContext);
  const [showModal, setShowModal] = useState(false);
  const [editMode, seteditMode] = useState(false);
  const [id, setId] = useState(null);
  const [formData, setformData] = useState({
    dose: '',
    ingredients: ''
  })
  const [productData, setproductData] = useState([]);

  const handleModal = (id, rowData) => {

    setId(id);
    setShowModal(true);
    getProducts(id);
  }
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

  }



  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: " ",
        accessor: " ",
        Cell: ({ value, row }) => {
          if (row.original.images.length) {
            return (<><img src={row.original.images[0].src} /></>)
          } else {
            return (<></>)
          }
        }
      },
      {
        Header: "Name",
        accessor: "title",
      },
      {
        Header: "SKU",
        accessor: "handle",
      },
      {
        Header: "Vendor",
        accessor: "vendor",
      },
      {
        Header: "Action",
        accessor: (row) => row.id,
        Cell: ({ value, row }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleModal(value)}
          >Edit</button>
        ),
      }

    ]);

  let navigate = useNavigate();

  const handleSubmit = () => {
    ProductServices.saveProductDose(id, formData)
      .then((response) => {
        
        setformData({
          dose: '',
          ingredients: '',
        });
        setShowModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
const getProducts = async (id) => {

    ProductServices.getProductDose(id)
      .then((response) => {
       
        if (response.success !== false) {
          setProductData(true);
          const { dose, ingredients } = response[0];
          const getData = {
            dose: dose,
            ingredients: ingredients
}
        setformData(getData);
          
        }else{
          setformData({
            dose: '',
    ingredients: ''
          });
        }



      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchData() {
    setLoading(true)
    ProductServices.getAllProducts({ 'per_page': '100' })
      .then((response) => {

        let nValue = [];
        for (const x of response) {

          // if(isBulk(x.attributes)){
          // x.bulk = 'Yes';
          // }else {
          // x.bulk = 'No';
          // }
          nValue.push(x)

        }

        setProductsList(nValue)
        setTempData(nValue)
        setLoading(false)

      })
      .catch((err) => {
        notifyError("error");
        setLoading(false)

      });
  }

  const onProductSearch = (e) => {
    let t = [];

    if (e.target.value.length == 0) {
      setProductsList(tempData)
    } else {

      tempData.map((product) => {
        if (product.title.toLowerCase().includes(e.target.value.toLowerCase())) {
          t.push(product)
        }
      })
      setProductsList(t)
    }


  }
 const iStyle = {
    marginRight: "10px"
  }

 
 


  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card products">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
                <h4 className="card-title">Products</h4>
              </div>

              <div className="col-lg-6">
                <button type="button" className="btn btn-success" style={{ float: "right" }} onClick={() => window.open('http://picpaxnutrition.com/api/productexport', '_blank')}>
                  <i className="ti-download"></i>
                </button>
                <input type="text" className="form-control-lg" style={{ border: "1px solid #DFDFDF", float: "right", marginRight: "10px" }} placeholder="Search product...." onChange={onProductSearch} />
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={productsList}
                fetchData={fetchData}
                loading={loading}
                pageRecords={productsList.length}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose}>
        <div className="col-lg-12 grid-margin">
          <Modal.Header closeButton>
            <Modal.Title>Product Ingredients</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="form-group">
                  <label for="prodose">Dose</label>
                  <input type="text" name="dose" value={getProductData ? formData.dose : ''} onChange={handleData} className="form-control form-control-lg" />
                </div>
                <div class="form-group">
                  <label for="proingredients">Ingredients</label>
                  <input type="text" name="ingredients" value={getProductData ? formData.ingredients : ''} onChange={handleData} className="form-control form-control-lg" />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <input type="button" onClick={handleModalClose} className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} value="Cancel" />
            <button type="submit" onClick={handleSubmit} className="btn btn-danger" style={{ float: "right", "marginRight": "10px" }}>Save</button>
          </Modal.Footer>
        </div>
      </Modal>

    </>
  )
}
export default Products