import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import OrderServices from '../services/OrderServices';
import { useParams } from 'react-router-dom';
import { notifySuccess, notifyError } from '../utils/toast';
import { toTitleCase } from '../utils/functions'
import Loading from '../components/preloader/Loading';


function OrderDetail(props) {
    const [orderData, setOrderData] = useState({})
    const [loading, setLoading] = useState(true)
    const params = useParams();

    useEffect(() => {
        load_data()
    }, []);

    function load_data() {
        //  setLoading(true)
        OrderServices.getOrderById(params.id)
            .then((response) => {
                setOrderData(response)
                setLoading(false);
            })
            .catch((err) => {
                notifyError("Error");
                setLoading(false);
            });
    }

    return (
        <>
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="card-title"> Order {orderData.name} Details</h4>
                            </div>
                        </div>
                        {loading ? <Loading loading={loading} /> : (
                            <>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <h5>General</h5>
                                        <p>Order key: {orderData.checkout_token}</p>
                                        <p>Created at: {orderData.created_at}<br />
										Status: {(orderData.financial_status)}
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <h5>Billing</h5>
                                        <p>{orderData.billing_address.first_name} {orderData.billing_address.last_name}
                                            {orderData.billing_address.address1} <br />{orderData.billing_address.city}</p>
                                        <p><strong>Email: </strong><br />{orderData.email}</p>
                                        <p><strong>Phone: </strong><br />{orderData.billing_address.phone}</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <h5>Shipping</h5>
                                        <p>{orderData.shipping_address.first_name} {orderData.shipping_address.last_name}
                                            {orderData.shipping_address.address1} <br />{orderData.shipping_address.city}</p>
                                    </div>
                                </div>
                                <div className="row  mt-20">
                                    <div className="col-lg-12 table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <th>Item</th>
                                                <th>Qty</th>
                                                <th>Total ({orderData.currency})</th>
                                            </thead>
                                            <tbody>
                                                {orderData.line_items.map((item, i) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.price}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <table className="table" cellpadding="0px" cellSpacing="0px">
                                        <tr>
                                            <td width="90%" className="right-align">Tax: </td>
                                            <td>{orderData.currency} {orderData.total_tax}</td>
                                        </tr>
                                        <tr>
                                            <td className="right-align">Order Total: </td>
                                            <td>{orderData.currency} {orderData.total_price}</td>
                                        </tr>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default OrderDetail