import requests from './httpService';

const DoctorServices = {
  loginDoctor(body) {
    return requests.post(`/api/doctors/login`, body);
  },
  getAllDoctors(body) {
    return requests.get('/api/doctors/index', body);
  },
  createDoctor(body) {
    return requests.post('/api/doctors/create', body);
  },

  getDoctorById(id, body) {
    return requests.get(`/api/doctors/find?doctor_id=${id}`, body);
  },

  updateDoctor(id, body) {
    return requests.put(`/api/doctors/update?doctor_id=${id}`, body);
  },
  getAllSubAccounts(id) {
    return requests.get(`/api/doctors/accounts/find?id=${id}`, {});
  },
  createSubAccount(body) {
    return requests.post('/api/doctors/accounts/create', body);
  },
  updateAccount(id, body) {
    return requests.put(`/api/doctors/update?doctor_id=${id}`, body);
  },
  deleteAccount(id, body) {
    return requests.post(`/api/doctors/accounts/delete?id=${id}`, body);
  },
};

export default DoctorServices;
