import React, { useState, useContext } from 'react'
import Cookies from 'js-cookie';
import face from '../../assets/images/faces/face8.jpg';
import { AdminContext, GlobalContext } from '../../context/AdminContext';
import { useNavigate } from "react-router-dom";
import {TranslationContext} from '../../context/TranslationContext'


function TopMenu() {

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const { setOpenProfile } = useContext(GlobalContext);
  const { language } = useContext(TranslationContext);
  
  let navigate = useNavigate();
  
  const { dispatch } = useContext(AdminContext);
  const messageToggle = () => {
    setShowMessage(!showMessage)
  }

  const profileMenuToggle = () => {
    setshowProfileMenu(!showProfileMenu)
  }

  const logoutUser = () => {
    dispatch({ type: 'USER_LOGOUT', payload: null });
    Cookies.remove('adminInfo');

  }

  const openSubAccount = () => {
    setshowProfileMenu(false)
    navigate('/accounts')
  }

  const openNotifications = () => {
    setshowProfileMenu(false)
    navigate('/notifications')
  }

  const openProfilePage = () => {
    setshowProfileMenu(false)
    setOpenProfile(true)
  }

  const [showMessage, setShowMessage] = useState(false)
  const [showProfileMenu, setshowProfileMenu] = useState(false)
  return (
    <ul className="navbar-nav ms-auto">

      {adminInfo.panel_admin == "1" && (
        <></>
      )}
      <li className="nav-item">
        <form className="search-form" action="#">
          <i className="icon-search"></i>
          <input type="search" className="form-control" placeholder= {language.header.searchHere} title="Search here" />
        </form>
      </li>

      <li className="nav-item dropdown">
        <a className="nav-link count-indicator" onClick={openNotifications} id="countDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="icon-bell"></i>

        </a>
        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="countDropdown">
          <a className="dropdown-item py-3">
            <p className="mb-0 font-weight-medium float-left">You have 0 unread mails </p>
          </a>
        </div>
      </li>
      <li className="nav-item dropdown d-none d-lg-block user-dropdown">
        <button onClick={profileMenuToggle}  id="UserDropdown" style={{ border: "0px" }}>
          <img width="50px" height="50px"className="img-xs rounded-circle"  src={adminInfo.profile_picture} alt="Profile image" /> </button>
        <div className={showProfileMenu ? "dropdown-menu dropdown-menu-right navbar-dropdown show" : "dropdown-menu dropdown-menu-right navbar-dropdown"} aria-labelledby="UserDropdown">
          <div className="dropdown-header text-center">
            <img    width="50px" height="50px"  className="img-md rounded-circle" src={adminInfo.profile_picture} alt="Profile image" />
            <p className="mb-1 mt-3 font-weight-semibold">{adminInfo.name}</p>
            <p className="fw-light text-muted mb-0">{adminInfo.email}</p>
          </div>
          <a className="dropdown-item" href="javascript:void(0)" onClick={openProfilePage}><i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> {language.header.myProfile} </a>
          {adminInfo.panel_admin == "0" &&
            <>
              <a className="dropdown-item" href="javascript:void(0)" onClick={openSubAccount}><i className="dropdown-item-icon mdi mdi-account-multiple-outline text-primary me-2"></i> {language.header.subAccount} </a>
              <a className="dropdown-item" href="javascript:void(0)" onClick={openNotifications} ><i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2"></i> {language.header.notification} <span className="badge badge-pill badge-success">0</span></a>
            </>
          }
          <button className="dropdown-item" onClick={logoutUser}><i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>{language.header.signout}</button>
        </div>
      </li>
    </ul>
  )
}

export default TopMenu