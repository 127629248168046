import React from 'react'
import { useEffect, useState, useContext } from 'react';
import { AdminContext } from '../context/AdminContext';
import ReportServices from '../services/ReportServices';
import { AdminDetails } from '../utils/functions'
import Loading from '../components/preloader/Loading';
import { notifySuccess, notifyError } from '../utils/toast';
import {TranslationContext} from '../context/TranslationContext'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function Dashboard() {


  const [dashboardList, setDashboardList] = useState([])
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [label, setLabel] = useState([])
  const [values, setValues] = useState([])
  const [graphData, setGraphData] = useState({})
  const [loadGraph, setLoadGraph] = useState(false)
  const [options, setOptions] = useState({})
  const { language } = useContext(TranslationContext);


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  let d = AdminDetails()
  let accountId = 0
  let mainAccount = 0;
  if (d.panel_admin == "1") {
    accountId = 0
  }
  else if (d.parent_id === "0") {
    accountId = d.id
    mainAccount = 1
  } else {
    accountId = d.id
    mainAccount = 0
  }

  function load_data() {
    setLoading(true)
    ReportServices.getDashboard({ id: accountId, main_account: mainAccount, panel_admin: accountId == 0 ? 1 : 0 })
      .then((response) => {
        
        setDashboardList(response.data)

        var l = [];
        var v = []
        for (const i in response.data.graph) {
          l.push(i)
          v.push(response.data.graph[i].length)
        }
        setOptions({
          responsive: true,
          plugins: {
            legend: {
              position: 'none',
            },
            title: {
              display: true,
              text: language.dashboard.monthlySale,
            },
          },
        });

        const labels = l

        setGraphData({
          labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: v,
              backgroundColor: '#4280b0',
            }
          ],
        });


        setLoadGraph(true)
        setLoading(false);
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    load_data();
  }, [reload]);
  return (
    <>
      <div className="home-tab">
        <div className="tab-content tab-content-basic">
          <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">

            {loading ? <Loading loading={loading} /> : (
              <>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="statistics-details d-flex align-items-center justify-content-between">
                      <div>
            <p className="statistics-title">{language.dashboard.totalSale}</p>
                        <h3 className="rate-percentage">{dashboardList.total_sum.toFixed(2)}</h3>
                      </div>
                      <div>
                        <p className="statistics-title">{language.dashboard.totalOrders}</p>
                        <h3 className="rate-percentage">{dashboardList.total_orders}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {loadGraph &&
                    <Bar options={options} data={graphData} />
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Dashboard