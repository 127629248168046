import{useState, useEffect, useMemo, useContext, React}  from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext, AdminProvider } from '../context/AdminContext';
import useAsync from '../hooks/useAsync';
import useFilter from '../hooks/useFilter';
import OrderServices from '../services/OrderServices';
import { useNavigate } from "react-router-dom";
import Loading from '../components/preloader/Loading';
import { toTitleCase, AdminDetails, isPanelOrder } from '../utils/functions';
import { notifySuccess, notifyError } from '../utils/toast';
import DataTable from '../components/DataTable';
import { TranslationContext } from '../context/TranslationContext';

function DraftOrder() {
   
  
    let navigate = useNavigate();
  
    const [data, setData] = useState([]);
    let d = AdminDetails();
    
    const [loading, setLoading] = useState(false);
    const { language } = useContext(TranslationContext);
  
    const createOrder = (id, rowData) => {
      navigate(`/orders/create`,{ state: { rowData } })
      }
   
    const columns = useMemo(
      () => [
        {
            Header: "Order Number",
            accessor: "id", 
          },
          {
            Header: "Customer Name",
            accessor: (row) => `${row.firstname} ${row.lastname}`, 
            Cell: ({ value }) => value,
          },
          {
            Header: "Email",
            accessor: "email", 
          },
    //       {
    //         Header: "Total Items",
    // accessor: "items", 
    // Cell: ({ value }) => {
    //     const itemsArray = JSON.parse(value); 
    //     const productCount = itemsArray.length;
    //     return productCount;

    //       }
    //     },
          {
            Header: language.doctors.lCreatedAt,
            accessor: "created_at",
          },
          {
            Header: "Action",
            accessor: (row) => row.id,
            Cell: ({ value, row }) => (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => createOrder(value, row.original)} 
              >
                Create Order
              </button>
            ),
          }
        ]);
      function fetchData() {
        setLoading(true)
       OrderServices.getsaveOrder(d.id, {})
          .then((response) => {
            if(typeof response.success != 'undefined'){

            }else{
              setData(response);
            }
            setLoading(false);
          })
        //   .catch((err) => {
        //     notifyError(err ? err.response.data.message : err.message);
        //     setLoading(false);
        //   });
        }
  
    useEffect(() => {
    }, []);
  


    return(
        <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
  <h4 className="card-title">Draft order</h4>
              </div>
              
            </div>
            <div className="table-responsive">
              <DataTable
                columns= {columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageRecords={data.length}
              />
            </div>
          </div>
        </div>
      </div>
    </>
    )

}
export default DraftOrder