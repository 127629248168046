import { React, useState, useEffect, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import ProductServices from '../services/ProductServices';
import PatientmoduleServices from '../services/PatientmoduleServices';
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from '../utils/toast';
import Loading from '../components/preloader/Loading';
import { toTitleCase, isBulk } from '../utils/functions'
import DataTable from '../components/DataTable'
import { TranslationContext } from '../context/TranslationContext'


function Patientmodule() {

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const [productsList, setProductsList] = useState([])
  const [tempData, setTempData] = useState([])
  const [loading, setLoading] = useState(false);
  const { language } = useContext(TranslationContext);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Clinic Name",
        accessor: "clinic_name",
      },
      {
        Header: "Order details",
        accessor: "product_type",
        Cell: ({ value, row }) => {
          return  <button type="button" accessor="id"  className="btn btn-success parientexport" style={{ float: "left",padding:"10px" }} onClick={patientexport} >
          <i className="ti-download"></i>
        </button>
        }
      }
    ]);

  let navigate = useNavigate();

  const patientexport = (e) => {
    const id = e.target.closest('tr').firstChild.innerHTML;
    
    window.open('http://picpaxnutrition.com/api/orders/ordersexport?id='+id, '_blank');
  }

  function fetchData() {
    setLoading(true)
    PatientmoduleServices.getAllPatient({ 'id': adminInfo.id })
      .then((response) => {

        let nValue = [];
        for(const x of response){
            nValue.push(x)
        }
          
        setProductsList(nValue)
        setTempData(nValue)
        setLoading(false)

      })
      .catch((err) => {
        notifyError("error");
        setLoading(false)

      });
  }

  const onProductSearch = (e) => {
    let t = [];

    if (e.target.value.length == 0) {
     
      setProductsList(tempData)
    } else {

      tempData.map((product) => {
        if (product.name.toLowerCase().includes(e.target.value.toLowerCase()) || product.email.toLowerCase().includes(e.target.value.toLowerCase())) {
 
          t.push(product)
        }
      })
      setProductsList(t)
    }


  }


  const iStyle = {
    marginRight: "10px"
  }

  useEffect(() => {
    //load_data()
  }, []);


  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
                <h4 className="card-title">Patients</h4>
              </div>

              <div className="col-lg-6">
                <button type="button" className="btn btn-success" style={{ float: "right" }} onClick={() => window.open('http://picpaxnutrition.com/api/productexport', '_blank')}>
                  <i className="ti-download"></i>
                </button>
                <input type="text" className="form-control-lg" style={{ border: "1px solid #DFDFDF", float: "right", marginRight: "10px" }} placeholder="Search patient...." onChange={onProductSearch} />
              </div>
            </div>
            <div className="table-responsive">
           
                       <DataTable
                       columns={columns}
                       data={productsList}
                       fetchData={fetchData}
                       loading={loading}
                       pageRecords={productsList.length}
                     />
     
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Patientmodule