import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { useTable, usePagination } from "react-table";

function DataTable({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  pageRecords,
  reload
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,


    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: process.env.REACT_APP_PAGESIZE },
      manualPagination: false,
      pageCount: controlledPageCount
    },
    usePagination
  );


  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [reload]);

  return (
    <>
      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
              loading ? "" : (
                <>
                  <tr>
                    <td colSpan="1000">No record found</td>
                  </tr>
                </>)
            )}

        </tbody>
      </table>

      <div className="loading-class">
        {loading ? "Loading..." : (
          <>
            Showing {page.length} of ~{pageRecords}{" "}
                results
                </>

        )}
      </div>
      <div className="pagination">
        <button className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageCount}
          </strong>{" "}
        </span>
      </div>
    </>
  );
}

export default DataTable;