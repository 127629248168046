import React from 'react'
import SidebarContent from './SidebarContent'

function Sidebar({openMenu, showOpenMenu}) {
    return (
        <>
         <SidebarContent openMenu = {openMenu} showOpenMenu = {showOpenMenu} />
        </>
    )
}

export default Sidebar