import React, { useState, useContext } from 'react'
import { NavLink, Route, useLocation } from 'react-router-dom';
//import sidebar from '../../routes/sidebar';
import { AdminContext } from '../../context/AdminContext';
import {TranslationContext} from '../../context/TranslationContext'

function SidebarContent({openMenu, showOpenMenu}) {

    const { state } = useContext(AdminContext);
    const { adminInfo } = state;
    const location = useLocation()
    const { language } = useContext(TranslationContext);


    const sidebar = [
        {
          path: '/',
          icon: 'mdi mdi mdi-view-dashboard menu-icon',
          name: language.menu.dashboard,
          child: [],
          admin: false
        },
        {
          path: '/doctors',
          icon: 'menu-icon mdi mdi-ambulance',
          name: language.menu.doctors,
          child: [],
          admin: true
        },
        {
          path: '/orders',
          icon: 'menu-icon mdi mdi-cash-multiple',
          name: language.menu.orders,
          child: [],
          admin: false
        },
        {
          path: '/products',
          icon: 'menu-icon mdi mdi-animation',
          name: language.menu.products,
          child: [],
          admin: false
        },
        {
            path: '/patientmodule',
            icon: 'menu-icon mdi mdi-animation',
            name: language.menu.patient,
            child: [],
            admin: false
          },
      ];

    SidebarContent.defaultProps = {
        openMenu: false
      };


    return (
        <>
            <nav className={openMenu ? "sidebar sidebar-offcanvas active" : "sidebar sidebar-offcanvas"} id="sidebar">
                <ul className="nav" >
                    {sidebar.map((route) => (
                        ((route.admin && adminInfo.panel_admin == "1") || !route.admin) && (
                            <li className={route.path == location.pathname ? "nav-item active" : "nav-item"} key={route.name} >
                                <NavLink
                                    to={route.path}
                                    className="nav-link"
                                >
                                    <i className={route.icon}></i>
                                    <span className="menu-title">{route.name}</span>
                                </NavLink>
                            </li>
                        )
                    ))}
                    <li className="nav-item nav-category">help</li>
                    <li className="nav-item">
                        <a className="nav-link" href="mailto:hello@picpax.com" target="_blank">
                            <i className="menu-icon mdi mdi-headphones"></i>
                        <span className="menu-title">{language.menu.contactus}</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default SidebarContent