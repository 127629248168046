import { React, useState, useEffect, useMemo, useContext } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext, AdminProvider } from '../context/AdminContext';
import useAsync from '../hooks/useAsync';
import useFilter from '../hooks/useFilter';
import OrderServices from '../services/OrderServices';
import { useNavigate } from "react-router-dom";
import Loading from '../components/preloader/Loading';
import { toTitleCase, AdminDetails, isPanelOrder } from '../utils/functions';
import { notifySuccess, notifyError } from '../utils/toast';
import DataTable from '../components/DataTable';
import { TranslationContext } from '../context/TranslationContext';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
function Orders() {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let d = AdminDetails();
  let accountId = 0
  let mainAccount = 0;
  let adminPatent = 0;
  if (d.panel_admin == "1") {
    accountId = 0
  }
  else if (d.parent_id === "0") {
    accountId = d.id
    mainAccount = 1
    adminPatent = 0
  } else {
    accountId = d.id
    mainAccount = 0
    adminPatent = 1
  }

  let navigate = useNavigate();

  const [data, setData] = useState([])
  const [tempData, setTempData] = useState([])
  const [loading, setLoading] = useState(false);
  const { language } = useContext(TranslationContext);
  const years = range(1960, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const columns = useMemo(
    () => [
      {
        Header: language.orders.lOrderNumber,
        accessor: "name",
      },
      {
        Header: language.orders.lName,
        accessor: "customer.displayName",
        Cell: ({ value, row }) => {
          const customerName = row.original.customer?.displayName;
          return <>{customerName || "N/A"}</>;
        }
      },

      {
        Header: language.orders.lEmail,
        accessor: "email",
      },
      {
        Header: language.orders.lTotal,
        accessor: "totalPrice",
        Cell: ({ value, row }) => {
          return (<>{row.original.currency} {row.original.totalPrice} </>);
        },
      },
      {
        Header: language.orders.lCity,
        accessor: "created_at",
        Cell: ({ value, row }) => {
          if (row.original.shipping_address != undefined) {
            return toTitleCase(row.original.defaultAddress.city.replace("_", " "))
          } else {
            return "";
          }
        }
      },
      {
        Header: language.orders.lStatus,
        accessor: "financial_status",
        Cell: ({ value, row }) => {
          return <><strong><label className={getStatusColor(row.original.financial_status)}>{row.original.financial_status.toUpperCase()}</label></strong></>
        }
      },
      {
        Header: "",
        accessor: " ",
        Cell: ({ row }) => {
          // Extract the ID by splitting on "/"
          const id = row.original.id.split("/").pop(); // Extracts '6244799512862'

          return (
            <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={() => openOrderDetails(id)}
            >
              <i className="ti-eye"></i>
            </button>
          );
        }
      }
    ]);

  function fetchData() {
    setLoading(true)
    OrderServices.getAllOrders({ id: accountId, main_account: mainAccount, admin_patent: adminPatent, panel_admin: accountId == 0 ? 1 : 0 })
      .then((response) => {

        const excludedNames = ["#1242", "#1241", "#1236", "#1250"];

        const ordersArray = response
          .filter(item => !excludedNames.includes(item.node.name))
          .map(item => {
            return {
              id: item.node.id,
              name: item.node.name,
              currency: item.node.currencyCode,
              email: item.node.email,
              financial_status: item.node.displayFinancialStatus,
              totalPrice: item.node.totalPrice,
              createdAt: item.node.createdAt,
              customer: {
                displayName: item.node.customer.displayName,
                defaultAddress: item.node.customer.defaultAddress
              }
            };
          });

        setData(ordersArray)
        setTempData(ordersArray)
        setLoading(false);

      })
      .catch((err) => {
        notifyError("Error");
        setLoading(false);
      });
  }

  const createOrder = () => {
    navigate('/orders/create')
  }

  const draftOrder = () => {
    navigate('/orders/draftorders')
  }


  const onSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
     if (searchValue.length === 0) {
      setData(tempData);
    } else {
      const filteredData = tempData.filter((order) =>
        order.customer.displayName.toLowerCase().includes(searchValue) ||
        order.name.includes(searchValue) ||
        order.email.includes(searchValue)
      );
      setData(filteredData);
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "btn btn-info btn-sm"
        break;
      case "failed":
        return "btn btn-danger btn-sm"
        break;
      case "PAID":
        return "btn btn-warning btn-sm"
        break;
      case "payment-pending":
        return "btn btn-secondary btn-sm"
        break;
      case "REFUNDED":
        return "btn btn-primary btn-sm";
        break;
      case "PARTIALLY_REFUNDED":
        return "btn btn-info btn-sm";
        break;
      case "completed":
        return "btn btn-success btn-sm"
        break;
      case "cancelled":
        return "btn btn-danger btn-sm"
        break;
      case "PARTIALLY_REFUNDED":
        return "btn btn-info btn-sm"
        break;
      case "processing":
        return "btn btn-success btn-sm"
        break;
    }
  }

  const openOrderDetails = (id) => {
    navigate('/orders/' + id)
  }


  const iStyle = {
    marginRight: "10px"
  }

  useEffect(() => {
  }, []);

  const handleDateFilter = () => {
    if (!startDate && !endDate) {
      // If both dates are null, reset filtered data to original data
      setData(tempData);
    }else{
      const filteredResults = tempData.filter((item) => {
        const itemDate = new Date(item.createdAt);
      
        return (
          (!startDate || itemDate >= startDate) && 
          (!endDate || itemDate <= endDate)
        );
      });

    
  setData(filteredResults);
      if (filteredResults.length > 0) {
        setData(filteredResults);
       
      }else{
        setTimeout(()=>{
          setData(tempData);
          setStartDate('');
          setEndDate('')
        }, 5000)
      }
    
    }
  };
  const handleDateChange = (date) => {
    setStartDate(date); 

  const selectedDate = date.toISOString().split("T")[0];
  const filteredResults = tempData.filter((item) => {
    const itemDate = item.createdAt.split("T")[0]; 
    return itemDate === selectedDate;
  });

 
  
  setData(filteredResults);

  if (filteredResults.length > 0) {
    setData(filteredResults);
   
  }else{
    setTimeout(()=>{
      setData(tempData);
      setStartDate('')
    }, 5000)
  }
 
  };


  return (

    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
                <h4 className="card-title">{language.orders.order}</h4>
              
              </div>
              <div className="col-lg-6">

                <button type="button" className="btn btn-success" style={{ float: "right" }} onClick={() => window.open('http://picpaxnutrition.com/api/orders/ordersexport?id=' + d.id, '_blank')}>
                  <i className="ti-download"></i>
                </button>

                <button type="button" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} onClick={createOrder}><i className="ti-plus"></i>&nbsp;{language.orders.createOrder}</button>
                <button type="button" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} onClick={draftOrder} >&nbsp;Draft Orders</button>
                {/* <input type="text" className="form-control-lg" style={{ border: "1px solid #DFDFDF", float: "right", marginRight: "10px" }} placeholder={language.orders.searchOrder} onChange={onSearch} />
                <DatePicker
                        className="form-control form-control-lg"
                        name="addOrderDOB"
                       
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              {">"}
                            </button>
                          </div>
                        )}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      /> */}
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-lg-5'>
              <input
      type="text"
      className="form-control-lg"
      style={{
        border: "1px solid #DFDFDF",
        fontSize: "14px",
        padding: "9px",
        width: "100%",
      }}
      placeholder={language.orders.searchOrder}
      onChange={onSearch}
    /> 
              </div>
              <div className='col-lg-7'>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
      
              <DatePicker 
          selected={startDate} 
          onChange={(date) => setStartDate(date)} 
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          className="form-control form-control-lg date_filter"
          isClearable
          placeholderText="Select start date"
        />
     
      
     
        <DatePicker 
          selected={endDate} 
          onChange={(date) => setEndDate(date)} 
          selectsEnd
           className="form-control form-control-lg  date_filter" 
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          isClearable
          placeholderText="Select end date"
        />
       <button type="button" className="btn btn-primary" style={{ float: "right", "marginRight": "10px" }} onClick={handleDateFilter} >Apply Now</button>
              </div>
            </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageRecords={data.length}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Orders