import axios from 'axios';
import Cookies from 'js-cookie';
import { getQueryParams } from '../utils/functions'


const instance = axios.create({
  baseURL: process.env.REACT_APP_REST_API_DEV,
  timeout: 500000,
  crossDomain: true,
  auth: {
    username: process.env.REACT_APP_REST_API_USERNAME,
    password: process.env.REACT_APP_REST_API_PASSWORD
  },
  headers:{
    'Access-Control-Allow-Origin': '*'
}

});

const responseBody = (response) => response.data;

const requests = {
  get: (url, body, headers) =>
    instance.get(url+ ((url.includes("?")) ? "&" : "?") + "consumer_key=" + process.env.REACT_APP_REST_API_USERNAME + "&consumer_secret=" + process.env.REACT_APP_REST_API_PASSWORD, body, headers).then(responseBody),

  post: (url, body) => instance.post(url + ((url.includes("?")) ? "&" : "?") + "consumer_key=" + process.env.REACT_APP_REST_API_USERNAME + "&consumer_secret=" + process.env.REACT_APP_REST_API_PASSWORD, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url + ((url.includes("?")) ? "&" : "?") + "consumer_key=" + process.env.REACT_APP_REST_API_USERNAME + "&consumer_secret=" + process.env.REACT_APP_REST_API_PASSWORD, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url +  ((url.includes("?")) ? "&" : "?") + "consumer_key=" + process.env.REACT_APP_REST_API_USERNAME + "&consumer_secret=" + process.env.REACT_APP_REST_API_PASSWORD, body).then(responseBody),

  delete: (url) => instance.delete(url + ((url.includes("?")) ? "&" : "?") + "consumer_key=" + process.env.REACT_APP_REST_API_USERNAME + "&consumer_secret=" + process.env.REACT_APP_REST_API_PASSWORD).then(responseBody),
};

export default requests;
