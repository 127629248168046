import { React, useState, useEffect, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import NotificationServices from '../services/NotificationServices';
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from '../utils/toast';
import Loading from '../components/preloader/Loading';
import { toTitleCase } from '../utils/functions'
import DataTable from '../components/DataTable'

function Notifications() {

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Notification",
        accessor: "post_title",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Crated at",
        accessor: "created_at",
      },
      {
        Header: "Status",
        accessor: "statu"
      }
    ]);

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const [notificationList, setNotificationList] = useState([])
  const [tempData, setTempData] = useState([])
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  function fetchData() {
    setLoading(true)
    NotificationServices.getAllNotifications(adminInfo.id)
      .then((response) => {
        setNotificationList(response.data)
        setTempData(response.data)
        setLoading(false);
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
        setLoading(false);
      });
  }

  const onSearch = (e) => {
    let t = [];

    if (e.target.value.length == 0) {
      setNotificationList(tempData)
    } else {

      tempData.map((notification) => {
        if ((notification.type.toLowerCase().includes(e.target.value.toLowerCase()) || (notification.post_title.toLowerCase().includes(e.target.value.toLowerCase())))) {
          t.push(notification)
        }
      })
      setNotificationList(t)
    }
  }

  const iStyle = {
    marginRight: "10px"
  }

  useEffect(() => {
  }, []);


  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-6">
                <h4 className="card-title">Notifications</h4>
              </div>

              <div className="col-lg-6">
                <button type="button" className="btn btn-success" style={{ float: "right" }} >
                  <i className="ti-download"></i>
                </button>
                <input type="text" className="form-control-lg" style={{ border: "1px solid #DFDFDF", float: "right", marginRight: "10px" }} placeholder="Search notification...." onChange={onSearch} />
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={notificationList}
                fetchData={fetchData}
                loading={loading}
                pageRecords={notificationList.length}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Notifications