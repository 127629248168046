import requests from './httpService';

const NotificationServices = {
  
  getAllNotifications(id, body) {
    return requests.get(`/notifications/${id}`, body);
  }
};

export default NotificationServices;
