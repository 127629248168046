import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import useAsync from "../hooks/useAsync";
import useFilter from "../hooks/useFilter";
import OrderServices from "../services/OrderServices";
import ProductServices from "../services/ProductServices";
import { notifySuccess, notifyError } from "../utils/toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button } from "react-bootstrap";
import { AdminDetails, isBulk } from "../utils/functions";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { useParams } from "react-router-dom";
import { TranslationContext } from "../context/TranslationContext";
import Autocomplete from "react-autocomplete";
import { FormControl, InputGroup, Form } from "react-bootstrap";
// import { test } from 'node:test';
// import { database } from 'faker/lib/locales/en';
import { useLocation } from "react-router-dom";

function CreateOrder() {
  let d = AdminDetails();

  const [tempData, setTempData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [showToggle, setShowToggle] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const [height, setHeight] = useState([]);
  const [doseData, setdoseData] = useState([]);
  const [showquantity, setShowquantity] = useState([false]);
  const { language } = useContext(TranslationContext);
  const [quantity, setQuantity] = useState("");
  var h = [];
  var x = 0;
  var y = 0;
  for (let i = 120; i <= 220; i++) {
    h.push(i);
  }

  const incrementQuantity = () => {
    const newQuantity = parseInt(quantity) + 1;
    setQuantity(newQuantity);
  };

  const decrementQuantity = () => {
    const newQuantity = parseInt(quantity) - 1;
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };
  const location = useLocation();
  const rowData = location.state?.rowData;

 

  function load_data() {
    ProductServices.getAllProducts({ per_page: "100" })
      .then((response) => {
       

        let tData = [];
        response.map((product) => {
    

          let dos = "pm";
          
          product.dosage = dos;

          

          tData.push(product);

        
        });
        setProductsList(tData);
      
        setTempData(tData);
        loadOrder(tData);
        let Ddata = [];
        ProductServices.getAllProductDose()
          .then((secondResponse) => {
            secondResponse.map((productDose) => {
              Ddata.push(productDose);
            });

            setdoseData(Ddata);
          })
          .catch((secondError) => {
        
          });
      })

      .catch((err) => {
        notifyError(err.message);
      });
  }

  let navigate = useNavigate();
  const addItems = () => {
    if (productsList.length == 0) {
      notifyError("Loading productions please wait...");
      return;
    } else {
      const tempDatav2 = tempData.map(p => {
        if(items.find(c => c.id == p.id)){
          p.isChecked = true
        }
        return p;
      })
     

      setProductsList(tempDatav2);
      setShowToggle(true);
    }
  };

  const closeAddItem = () => {
    setShowToggle(false);
  };

  useEffect(() => {
    load_data();
  }, []);
  useEffect(() => {
    // const allChecked = indicesToCheck.every(index => productsList[index].isChecked);
    for (let index = 0; index < productsList.length; index++) {
      if (
        (productsList[index].title === "62 Biomarkers Blood Analysis" &&
          productsList[index].isChecked === true) ||
        (productsList[index].title === "45 Biomarkers Blood Analysis" &&
          productsList[index].isChecked === true)
      ) {
        x++;
      } else {
        if (productsList[index].isChecked === true) {
          y++;
        }
      }
    }
    if (x > 0 && x <= 4 && y === 0) {
      setShowquantity(true);
    } else {
      setShowquantity(false);
    }
  
  });

  const onSelectHandler = (e) => {
 const { name, checked } = e.target;
   let tempProducts = productsList.map((product) =>
      product.id.toString() === name
        ? {
            ...product,
            isChecked: checked,
            quantity:
              product.title.toLocaleLowerCase().indexOf("pax") != -1 ||
              product.title === "45 Biomarkers Blood Analysis" ||
              product.title === "62 Biomarkers Blood Analysis"
                ? 1
                : process.env.REACT_APP_MINIMUM_QUANTITY,
            dosage: product.dosage,
          }
        : product
    );

    let tempProducts2 = tempData.map((product) =>
      product.id.toString() === name
        ? {
            ...product,
            isChecked: checked,
            quantity:
              product.title.toLocaleLowerCase().indexOf("pax") != -1 ||
              product.title === "45 Biomarkers Blood Analysis" ||
              product.title === "62 Biomarkers Blood Analysis"
                ? 1
                : process.env.REACT_APP_MINIMUM_QUANTITY,
            dosage: product.dosage,
          }
        : product
    );
setProductsList(tempProducts);
    setTempData(tempProducts2);
  
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let tempProducts = productsList.map((product) =>
      "quantity_" + product.id.toString() === name
        ? { ...product, quantity: 1 }
        : product
    );
    setProductsList(tempProducts);
  };

  const onSelectChangeHandler = (e) => {
    const { name, value } = e.target;
    let tempProducts = productsList.map((product) =>
      "dosage_" + product.id.toString() === name
        ? { ...product, dosage: value }
        : product
    );
    setProductsList(tempProducts);
  };

 

  const onProductSearch = (e) => {
  
    let t = [];

    if (e.target.value.length == 0) {
      setProductsList(tempData);
    } else {
      tempData.map((product) => {
     
       
        if (
          product.title.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          t.push(product);
        }
      });
      setProductsList(t);
  
    }
  };
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  function goToHome() {
    navigate("/orders");
  }

  const loadOrder = (tData) => {
 
    var item = rowData;
    if (!item) return false;

    setValue("addOrderFirstName", item.firstname != null ? item.firstname : "");
    setValue("addOrderLastName", item.lastname != null ? item.lastname : "");
    setValue("addOrderEmail", item.email != null ? item.email : "");
    setValue(
      "addOrderContactNumber",
      item.phonenumber != null ? item.phonenumber : ""
    );
    var itms = JSON.parse(rowData.items);
   
    let tempProducts = [];
    itms.map((product) => {
      var p = tData.find((p) =>
        p.variants.find((v) => v.id == product.variant_id)
      );
   
      p.variants[0].quantity = product.quantity;
      p.quantity = 1;
      tempProducts.push(p);
    });
   
    if (itms.length) {
      setItems(tempProducts);
    }
  };


  const addItemsToSession = () => {
    let tempProducts = [];
    tempData.map((product) => {
      if (product.isChecked) {
        tempProducts.push(product);
         
            product.quantity = 1;
        
      }
    });

  
    setItems(tempProducts);
    setShowToggle(false);
  };




  const deleteSelectedItem = (id) => {
    let tempProducts = [];

    items.map((item, i) => {
      if (id.toString() !== item.id.toString()) {
        tempProducts.push(item);
      }
    });
    setItems(tempProducts);

    var t = [];
    productsList.map((ite, i) => {
      if (id.toString() == ite.id.toString()) {
        ite.isChecked = false;
      }
      t.push(ite);
    });
    setProductsList(t);
  };

  const saveOrder = (data) => {
    var arr = "";
    items.forEach(function (res) {
      arr += res.title;
    });
  
    if (
      arr.indexOf("Pax") > -1 ||
      arr.toLowerCase().includes("blood analysis")
    ) {
      var bulkItem = false;
      items.map((item, i) => {
        if (item.bulk == "Yes") {
          bulkItem = true;
        }
      });

      const itemData = [];
      let total_price = 0;
      items.map((item, i) => {
        itemData.push({
          variant_id: item.variants[0].id,
          quantity: item.quantity,
          price: item.variants[0].price,
          name: item.title,
        });
        total_price += parseInt(item.variants[0].price) * item.quantity;
      });

      const postData = {
        draft_order: {
          customer: {
            first_name: data.addOrderFirstName,
            last_name: data.addOrderLastName,
            email: data.addOrderEmail,
            phone: "+" + data.addOrderContactNumber,
            parent_id: d.id,
          },
          billing_address: {
            first_name: data.addOrderFirstName,
            last_name: data.addOrderLastName,
            address_1: data.addOrderAddress,
            address_2: "",
            city: data.addOrderEmirates,
            state: "",
            postcode: "",
            country: "AE",
            email: data.addOrderEmail,
            phone: data.addOrderContactNumber,
          },
          shipping_address: {
            first_name: data.addOrderFirstName,
            last_name: data.addOrderLastName,
            address_1: data.addOrderAddress,
            address_2: "",
            city: data.addOrderEmirates,
            state: "",
            postcode: "",
            phone: data.addOrderContactNumber,
            country: "AE",
          },
          line_items: itemData,
          coupon: data.coupon,
          admin: d.parent_id == null ? 0 : d.id,
          tags:
            "partner_" +
            (d.parent_id == null ? d.id : d.parent_id) +
            ", sub_partner_" +
            (d.parent_id == null ? 0 : d.id),
          note_attributes: [
            {
              name: "partner",
              value: d.parent_id == null ? d.id : d.parent_id,
            },
            {
              name: "sub_partner",
              value: d.parent_id == null ? 0 : d.id,
            },
            {
              name: "sub_partner_name",
              value: d.name,
            },
            {
              name: "order_source",
              value: "panel",
            },
          ],
        },
        user_id: d.id,
      };

      if (total_price < 300) {
        postData.draft_order.shipping_line = true;
      }
      setSubmiting(true);
      OrderServices.saveOrder(postData)

        .then((response) => {
          if (response) {
            setSubmiting(false);
            notifySuccess("Action completed successfully!");
          }
          navigate("/orders/draftorders");
        })
        .catch((err) => {
          setSubmiting(false);
          notifyError(err ? err.response.data.message : err.message);
        });
    }  else {
      if (items.length < 1) {
       
        notifyError("Please add minimum 1 items in the order");
        return;
      } 
        var bulkItem = false;
        items.map((item, i) => {
          if (item.bulk == "Yes") {
            bulkItem = true;
          }
        });

        const itemData = [];
        let total_price = 0;
        items.map((item, i) => {
          itemData.push({
            variant_id: item.variants[0].id,
            quantity: item.quantity,
          });
          total_price += parseInt(item.variants[0].price) * item.quantity;
        });

        const postData = {
          draft_order: {
            customer: {
              first_name: data.addOrderFirstName,
              last_name: data.addOrderLastName,
              email: data.addOrderEmail,
              phone: "+" + data.addOrderContactNumber,
              parent_id: d.id,
            },
            billing_address: {
              first_name: data.addOrderFirstName,
              last_name: data.addOrderLastName,
              address_1: data.addOrderAddress,
              address_2: "",
              city: data.addOrderEmirates,
              state: "",
              postcode: "",
              country: "AE",
              email: data.addOrderEmail,
              phone: data.addOrderContactNumber,
            },
            shipping_address: {
              first_name: data.addOrderFirstName,
              last_name: data.addOrderLastName,
              address_1: data.addOrderAddress,
              address_2: "",
              city: data.addOrderEmirates,
              state: "",
              postcode: "",
              phone: data.addOrderContactNumber,
              country: "AE",
            },
            line_items: itemData,
            coupon: data.coupon,
            admin: d.parent_id == null ? 0 : d.id,
            tags:
              "partner_" +
              (d.parent_id == null ? d.id : d.parent_id) +
              ", sub_partner_" +
              (d.parent_id == null ? 0 : d.id),
            note_attributes: [
              {
                name: "partner",
                value: d.parent_id == null ? d.id : d.parent_id,
              },
              {
                name: "sub_partner",
                value: d.parent_id == null ? 0 : d.id,
              },
              {
                name: "sub_partner_name",
                value: d.name,
              },
              {
                name: "order_source",
                value: "panel",
              },
            ],
          },
        };

        if (total_price < 300) {
          postData.draft_order.shipping_line = true;
        }
        setSubmiting(true);
        OrderServices.saveOrder(postData)
          .then((response) => {
            if (response) {
              setSubmiting(false);
              notifySuccess("Action completed successfully!");
              navigate("/orders");
            }
          })
          .catch((err) => {
            setSubmiting(false);
            notifyError(err ? err.response.data.message : err.message);
          });
      }
    }
  

  const onSubmit = (data) => {
    var item = rowData;
    if (item) {
      let orderid = item.id;
      OrderServices.updateSaveOrder(orderid, {})
        .then((response) => {
        
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
        });
    }

    var arr = "";
    items.forEach(function (res) {
      arr += res.title;
    });
   
    if (
      arr.indexOf("Pax") > -1 ||
      arr.toLowerCase().includes("blood analysis")
    ) {
      var bulkItem = false;
      items.map((item, i) => {
        if (item.bulk == "Yes") {
          bulkItem = true;
        }
      });

      const itemData = [];
      let total_price = 0;

      items.map((item, i) => {
        itemData.push({
          variant_id: item.variants[0].id,
          quantity: item.quantity,
          price: item.variants[0].price,
          name: item.title,
        });
        total_price += parseInt(item.variants[0].price) * item.quantity;
      });

      const postData = {
        draft_order: {
          customer: {
            first_name: data.addOrderFirstName,
            last_name: data.addOrderLastName,
            email: data.addOrderEmail,
            phone: "+" + data.addOrderContactNumber,
            parent_id: d.id,
          },
          billing_address: {
            first_name: data.addOrderFirstName,
            last_name: data.addOrderLastName,
            address_1: data.addOrderAddress,
            address_2: "",
            city: data.addOrderEmirates,
            state: "",
            postcode: "",
            country: "AE",
            email: data.addOrderEmail,
            phone: data.addOrderContactNumber,
          },
          shipping_address: {
            first_name: data.addOrderFirstName,
            last_name: data.addOrderLastName,
            address_1: data.addOrderAddress,
            address_2: "",
            city: data.addOrderEmirates,
            state: "",
            postcode: "",
            phone: data.addOrderContactNumber,
            country: "AE",
          },
          line_items: itemData,
          coupon: data.coupon,
          admin: d.parent_id == null ? 0 : d.id,
          tags:
            "partner_" +
            (d.parent_id == null ? d.id : d.parent_id) +
            ", sub_partner_" +
            (d.parent_id == null ? 0 : d.id),
          note_attributes: [
            {
              name: "partner",
              value: d.parent_id == null ? d.id : d.parent_id,
            },
            {
              name: "sub_partner",
              value: d.parent_id == null ? 0 : d.id,
            },
            {
              name: "sub_partner_name",
              value: d.name,
            },
            {
              name: "order_source",
              value: "panel",
            },
          ],
        },
      };

      if (total_price < 300) {
        postData.draft_order.shipping_line = true;
      }
      setSubmiting(true);
      OrderServices.createOrder(postData)
        .then((response) => {
          if (response) {
            setSubmiting(false);
            notifySuccess("Action completed successfully!");
            navigate("/orders");
          }
        })
        .catch((err) => {
          setSubmiting(false);
          notifyError(err ? err.response.data.message : err.message);
        });
    } else {
      if (items.length < 1) {
       
        notifyError("Please add minimum 1 items in the order");
        return;
      } else {
        var bulkItem = false;
        items.map((item, i) => {
          if (item.bulk == "Yes") {
            bulkItem = true;
          }
        });

        const itemData = [];
        let total_price = 0;
        items.map((item, i) => {
          itemData.push({
            variant_id: item.variants[0].id,
            quantity: item.quantity,
          });
          total_price += parseInt(item.variants[0].price) * item.quantity;
        });

        const postData = {
          draft_order: {
            customer: {
              first_name: data.addOrderFirstName,
              last_name: data.addOrderLastName,
              email: data.addOrderEmail,
              phone: "+" + data.addOrderContactNumber,
              parent_id: d.id,
            },
            billing_address: {
              first_name: data.addOrderFirstName,
              last_name: data.addOrderLastName,
              address_1: data.addOrderAddress,
              address_2: "",
              city: data.addOrderEmirates,
              state: "",
              postcode: "",
              country: "AE",
              email: data.addOrderEmail,
              phone: data.addOrderContactNumber,
            },
            shipping_address: {
              first_name: data.addOrderFirstName,
              last_name: data.addOrderLastName,
              address_1: data.addOrderAddress,
              address_2: "",
              city: data.addOrderEmirates,
              state: "",
              postcode: "",
              phone: data.addOrderContactNumber,
              country: "AE",
            },
            line_items: itemData,
            coupon: data.coupon,
            admin: d.parent_id == null ? 0 : d.id,
            tags:
              "partner_" +
              (d.parent_id == null ? d.id : d.parent_id) +
              ", sub_partner_" +
              (d.parent_id == null ? 0 : d.id),
            note_attributes: [
              {
                name: "partner",
                value: d.parent_id == null ? d.id : d.parent_id,
              },
              {
                name: "sub_partner",
                value: d.parent_id == null ? 0 : d.id,
              },
              {
                name: "sub_partner_name",
                value: d.name,
              },
              {
                name: "order_source",
                value: "panel",
              },
            ],
          },
        };

        if (total_price < 300) {
          postData.draft_order.shipping_line = true;
        }
        setSubmiting(true);
        OrderServices.createOrder(postData)
          .then((response) => {
            if (response) {
              setSubmiting(false);
              notifySuccess("Action completed successfully!");
              navigate("/orders");
            }
          })
          .catch((err) => {
            setSubmiting(false);
            notifyError(err ? err.response.data.message : err.message);
          });
      }
    }
  };

  const years = range(1960, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [search, setSearch] = useState("");
  const [searchlist, setSearchlist] = useState([]);

  function getCustomers(str) {
    setSearch(str);
    var parm = "first_name:" + str + " OR email:" + str;
    OrderServices.searchCustomers(parm)
      .then((response) => {
        if (response) {
          setSubmiting(false);
          setSearchlist(response);
        }
      })
      .catch((err) => {
        setSubmiting(false);
        notifyError("Error in api");
      });
  }

  function matchCustomers(state, value) {


    // Check if state, email, and first_name are valid before calling toLowerCase
    const emailMatch = state?.email && state.email.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    const firstNameMatch = state?.first_name && state.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    return emailMatch || firstNameMatch;
}

  function onCustomerSelect(customer, item) {
    setSearch(customer);
   
    setValue(
      "addOrderFirstName",
      item.first_name != null ? item.first_name : ""
    );
    setValue("addOrderLastName", item.last_name != null ? item.last_name : "");
    setValue("addOrderEmail", item.email != null ? item.email : "");
    setValue("addOrderContactNumber", item.phone != null ? item.phone : "");
    var default_address = item.default_address
      ? item.default_address.address1 != null
        ? item.default_address.address1 + ", " + item.default_address.city
        : ""
      : "";
    setValue("addOrderAddress", default_address);
  }

  let subtotal = 0;
  items.forEach((item) => {
    subtotal += item.quantity * parseFloat(item.variants[0].price);
  });

  const handleQuantityChange = (index, value) => {
   
    const pr = [...items];
    pr[index].quantity = value;
    setItems(pr);
  };

  useEffect(()=>{
    
  })

 

  return (
    <>
      <form className="form-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h4 className="card-title">{language.orders.createOrder}</h4>
                </div>
                <div className="col-lg-12">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={goToHome}
                    style={{ float: "right" }}
                  >
                    <i className="ti-back-left"></i>&nbsp;&nbsp;
                    {language.orders.cancel}
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={addItems}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    <i className="ti-plus"></i>&nbsp;&nbsp;
                    {language.orders.items}
                  </button>
                  <input
                    type="submit"
                    className="btn btn-primary"
                    style={{ float: "right", marginRight: "10px" }}
                    value={
                      submiting
                        ? language.orders.creating
                        : language.orders.createOrder
                    }
                  />
                  <button
                    type="submit"
                    className="btn btn-info"
                    onClick={handleSubmit(saveOrder)}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp; Save
                  </button>
                </div>
              </div>

              {/* <div className="row">
								<div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-sm-9">
                                            <input type="text" placeholder="Seach Existing Customer By Email" className="form-control form-control-lg" name="search" value={search} onInput={(e) => setSearch(e.target.value)}  />
                                        </div>
                                        <div className='col-sm-3'>
                                            <button type="button" className="btn btn-primary" onClick={searchCustomer} title="Search Customer"><i className="ti-search"></i></button>
                                        </div>
                                    </div>
                                </div>
							</div> */}

              <div className="row">
                <div className="col-md-6">
                  <Autocomplete
                    value={search}
                    inputProps={{
                      id: "states-autocomplete",
                      className: "form-control form-control-lg",
                      placeholder: "Search Existing customer",
                    }}
                    wrapperStyle={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                    items={searchlist}
                    getItemValue={(item) => item.email}
                    shouldItemRender={matchCustomers}
                    onChange={(event, value) => getCustomers(value)}
                    onSelect={(value, item) => onCustomerSelect(value, item)}
                    renderMenu={(children) => (
                      <div className="menu">{children}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.id}
                      >
                        {item.email}
                      </div>
                    )}
                  />
                </div>
              </div>
              <p>&nbsp;</p>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.firstName}{" "}
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="addOrderFirstName"
                        {...register("addOrderFirstName", {
                          required: language.orders.errors.lastName,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="addOrderFirstName"
                        render={({ message }) => (
                          <p className="form-error">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.lastName}{" "}
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="addOrderLastName"
                        {...register("addOrderLastName", {
                          required: language.orders.errors.lastName,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="addOrderLastName"
                        render={({ message }) => (
                          <p className="form-error">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.email}{" "}
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="addOrderEmail"
                        {...register("addOrderEmail", {
                          required: language.orders.errors.email,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: language.orders.errors.email,
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="addOrderEmail"
                        render={({ message }) => (
                          <p className="form-error">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.contactNumber}{" "}
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="9715XXXXXXXX"
                        name="addOrderContactNumber"
                        {...register("addOrderContactNumber", {
                          required: language.orders.errors.contactNumber,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="addOrderContactNumber"
                        render={({ message }) => (
                          <p className="form-error">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.weight}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        min="1"
                        max="400"
                        className="form-control form-control-lg"
                        name="addOrderWeight"
                        {...register("addOrderWeight")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.height}
                    </label>
                    <div className="col-sm-9">
                      <div className="row">
                        <div className="col-sm-12">
                          <select
                            className="form-control form-control-lg"
                            name="addOrderFeets"
                            {...register("addOrderFeets")}
                            style={{ "-webkit-appearance": "listbox" }}
                          >
                            {h.map((x) => (
                              <option value={x}>{x}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      {language.orders.DOB} <span className="required">*</span>
                    </label>
                    <div className="col-sm-9">
                      {/*<DatePicker selected={new Date(new Date().setFullYear(new Date().getFullYear() - 18))} onChange={(date) => setStartDate(date)} maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))} name="addOrderDOB" {...register("addOrderDOB")} className="form-control form-control-lg" />*/}
                      <DatePicker
                        className="form-control form-control-lg"
                        name="addOrderDOB"
                        {...register("addOrderDOB")}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              {">"}
                            </button>
                          </div>
                        )}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Gender</label>
                    <div className="col-sm-9">
                      <select
                        className="form-control form-control-lg"
                        name="addOrderGender"
                        {...register("addOrderGender")}
                        style={{ "-webkit-appearance": "listbox" }}
                      >
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Coupon (optional)
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="coupon"
                        {...register("coupon")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="coupon"
                        render={({ message }) => (
                          <p className="form-error">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Notes (optional)
                    </label>
                    <div className="col-sm-9">
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        rows={3}
                        placeholder="Add Notes  "
                      />
                    </div>
                  </div>
                </div>
              </div>

              {items.length > 0 ? (
                <div className="row">
                  <h4>Selected products ({items.length})</h4>
                  <div className="col-lg-12 grid-margin">
                    <table className="table table-red crtorder">
                      <tr>
                        <th width="5%"></th>
                        <th>{language.orders.products.product}</th>
                        <th>{language.orders.products.sku}</th>
                        <th>
                          {showquantity === false
                            ? language.orders.products.quantity
                            : " "}
                        </th>

                        <th>Price</th>
                      </tr>
                      {items.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <img
                              src={item.images[0].src.replace("https", "http")}
                            />
                          </td>
                          <td>{item.title}</td>
                          <td>{item.variants[0].sku}</td>
                          <td>
                            <InputGroup style={{ width: "50%" }}>
                            <Button
  variant="outline-secondary"
  onClick={(e) => {
    if (parseInt(item.quantity) > 1) {
      handleQuantityChange(i, parseInt(item.quantity) - 1);
    }
  }}
  style={{
    padding: "0.2rem 0.4rem",
    height: "32px",
  }}
>
-

                                
                              </Button>
                              <FormControl type="text" value={item.quantity} />
                              <Button
  variant="outline-secondary"
  onClick={(e) =>
    handleQuantityChange(i, parseInt(item.quantity) + 1)
  }
  style={{
    padding: "0.2rem 0.4rem",
    height: "32px",
  }}
>
  +
</Button>
                            </InputGroup>
 </td>
                          <td> 
                            AED{" "}
                            {item.quantity * parseFloat(item.variants[0].price)}
                          </td>
                          <th>
                            <button
                              type="button"
                              className="delte-button"
                              onClick={() => deleteSelectedItem(item.id)}
                            >
                              <i className="ti-trash"></i>{" "}
                              {language.orders.products.remove}
                            </button>
                          </th>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colSpan="5"
                          style={{ textAlign: "right", fontWeight: "bold" }}
                        >
                          Subtotal
                        </td>
                        <td>AED {subtotal.toFixed(2)}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>

      <Modal show={showToggle} size="lg">
        <div className="col-lg-12 grid-margin">
          <div className="card111">
            <div className="card-body111">
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <table className="table">
                      <tr>
                        <td colSpan="6">
                          <input
                            type="text"
                            name="order_product"
                            id="order_product"
                            placeholder="Search product ...."
                            className="form-control form-control-lg"
                            onChange={onProductSearch}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th width="1%"></th>
                        <th width="5%"></th>
                        <th>{language.orders.products.product}</th>
                        <th>{language.orders.products.sku}</th>
                        <th>Dose</th>
                        <th>Ingredients</th>
                      </tr>
                      <tbody>
                        {productsList.map((product, i) => (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={product.id}
                                onChange={onSelectHandler}
                                style={{ width: "20px", height: "20px" }}
                                checked={product.isChecked ? true : false}
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  product.images.length
                                    ? product.images[0].src
                                    : ""
                                }
                              />
                            </td>
                            <td>{product.title}</td>
                            <td>{product.variants[0].sku}</td>
                            <td>
                              {
                                doseData.find(
                                  (doseItem) =>
                                    doseItem.product_id == product.id.toString()
                                )?.dose
                              }
                            </td>
                            <td>
                              {
                                doseData.find(
                                  (doseItem) =>
                                    doseItem.product_id == product.id.toString()
                                )?.ingredients
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ float: "right", marginRight: "10px" }}
                  value="Add items"
                  onClick={addItemsToSession}
                >
                  {language.orders.addItems}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  style={{ float: "right", marginRight: "10px" }}
                  onClick={closeAddItem}
                >
                  {language.orders.cancel}
                </button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateOrder;
