import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Loading = ({ loading }) => {
  return (
    <div className="text-lg text-center py-6">
      <PulseLoader
        color="#4280b0"
        loading={loading}
        height={25}
        width={3}
        radius={3}
        margin={4}
      />
    </div>
  );
};

export default Loading;
