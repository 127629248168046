import { lazy } from 'react';
import Dashboard from  '../pages/Dashboard';
import Doctors from  '../pages/Doctors';
import SubAccount from '../pages/SubAccount'
import Orders from  '../pages/Orders';
import OrderDetail from  '../pages/OrderDetail';
import CreateOrder from  '../pages/CreateOrder';
import Notifications from '../pages/Notifications'
import Products from '../pages/Products'
import Patientmodule from '../pages/Patientmodule'
import Reports from '../pages/Reports'
import NotFound from  '../pages/404';
import Mtable from  '../pages/Mtable';
import DraftOrder from '../pages/DraftOrder'

const pageRoutes = [
  {
    path: '/',
    component: Dashboard,
    admin: false
  },
  {
    path: '/doctors',
    component: Doctors,
    admin: true
  },
  {
    path: '/accounts',
    component: SubAccount,
    admin: false
  },
  {
    path: '/orders',
    component: Orders,
    admin: false
  },
  {
    path: '/notifications',
    component: Notifications,
    admin: false
  },
  {
    path: '/orders/:id',
    component: OrderDetail,
    admin: false
  },
  {
    path: '/orders/create',
    component: CreateOrder,
    admin: false
  },
  {
    path: '/products',
    component: Products,
    admin: false
  },
  {
    path: '/patientmodule',
    component: Patientmodule,
    admin: false
  },
  {
    path: '/orders/draftorders',
    component: DraftOrder,
    admin: false
  },
  /*{
    path: '/reports',
    component: Reports,
    admin: false
  },*/
  /*{
    path: '/customers',
    component: Customers,
  },*/
  
  {
    path: '404',
    component: NotFound,
  }
];

export default pageRoutes;
