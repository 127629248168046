import React, { useContext } from 'react'
import TopMenu from './TopMenu'
import logo from '../../assets/images/login.png';
import { AdminContext } from '../../context/AdminContext';

import { useState } from 'react'
function TopBar({showOpenMenu}) {

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const [showFixedMenu, setShowFixedMenu] = useState(false)

  const toggleMenu = () => {
    if (!showFixedMenu) {

      let bodyElement = document.getElementsByTagName('body')[0];
      bodyElement.className = 'sidebar-icon-only';
      setShowFixedMenu(true)

    } else {

      let bodyElement = document.getElementsByTagName('body')[0];
      bodyElement.className = '';
      setShowFixedMenu(false)
    }
  }
  return (
    <>
      <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
          <div className="me-3">
            <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize" onClick={toggleMenu}>
              <span className="icon-menu"></span>
            </button>
          </div>
          <div>
            <a className="navbar-brand brand-logo">
              <img src={logo} alt="logo" />
              <p>Doc Portal</p>
            </a>
            <a className="navbar-brand brand-logo-mini" >
              <img src={logo} alt="logo" />
            </a>
          </div>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-top">
          <ul className="navbar-nav">
            <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
              <h1 className="welcome-text">Hi, <span className="text-black fw-bold">{adminInfo.title}</span></h1>
            </li>
          </ul>
          <TopMenu />
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" onClick = {showOpenMenu} type="button" data-bs-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  )
}

export default TopBar
