import requests from './httpService';

const ProductServices = {
  
  getAllProducts(body) {
    return requests.get('/api/products?per_page=' + body.per_page, body);
  },
  saveProductDose(id,body){
    return requests.post(`/api/productdose?id=${id}`, body);
  },
  getProductDose(id,body){
    return requests.get(`/api/getproducts?id=${id}`, body);
  },
  getAllProductDose(body){
    return requests.get('/api/getproductsdose', body);
  },

};

export default ProductServices;
