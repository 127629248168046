import { React, useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AdminContext } from '../context/AdminContext';
import ProductServices from '../services/ProductServices';
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from '../utils/toast';
import Loading from '../components/preloader/Loading';
import { toTitleCase } from '../utils/functions'

function Reports() {

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-12">
                <h4 className="card-title">Reports</h4>
              </div>
            </div>
            <div className="row mt-20" style={{ minHeight: '500px' }}>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default Reports