import { useState, useContext } from 'react'
import { AdminContext } from '../context/AdminContext';


export const AdminDetails = () => {

  const { state } = useContext(AdminContext)
  const { adminInfo } = state
  return adminInfo
}


const getQueryParams = (url) => {
  try{
      var query_str = url.substr(url.indexOf('?')+1, url.length-1);
      var r_params = query_str.split('&');
      var params = {}
      for( var i in r_params){
          var param = r_params[i].split('=');
          params[ param[0] ] = param[1];
      }
      return params;
  }
  catch(e){
     return 0;
  }
}

const toTitleCase = (s)=>{
  
    if (typeof(s)==='string'&&s.length>0) {
      const words = s.split(' ')
      if (Array.isArray(words)&&words.length>0) {
        if (words.length===1) {
          const word = words[0]
          const matches = word.charAt(0).match(/\w+/i)
          const lines = word.split('\n')
          if (Array.isArray(lines)&&lines.length>1) {
            return lines.map(line=>{
              return toTitleCase(line)
            }).join('\n')
          } else if (Array.isArray(matches)) {
            return word.split('').map((c,i)=>{
              if (i===0) {
                return c.toUpperCase()
              }
              return c.toLowerCase()
            }).join('')
          } else {
            return word.charAt(0).concat(toTitleCase(word.slice(1)))
          }
        } else {
          return words.map(word=>toTitleCase(word)).join(' ')
        }
      }
    }
    return ''
  }


  const isBulk = (attributes) => {

    if (isBulk.length == 0){
      return false;
    }else {
      for(const i of attributes){
        if(i.name = 'Bulk Item'){
          return true;
        }
      }
    }
    return false;
  }


  const isPanelOrder = (meta) => {

    if (isBulk.length == 0){
      return false;
    }else {
      for(const i of meta){
        if(i.key = 'order_source' && i.value == 'panel'){
          return true;
        }
      }
    }
    return false;
  }


  export {toTitleCase, getQueryParams, isBulk, isPanelOrder}