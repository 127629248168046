import React, { lazy , Suspense} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import PrivateRoute from './components/login/PrivateRoute';
import Layout from './layout/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';


import {TranslationProvider} from './context/TranslationContext'


const App = () => {
  return (
    <TranslationProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />} />
            <Route path="/*" element={<Layout />} />
          </Route>
        </Routes>
      </Router>
      </TranslationProvider>
  );
};

export default App;
