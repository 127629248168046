import requests from './httpService';

const OrderServices = {

  getAllOrders(body) {
    return requests.post('/api/orders/panel_orders', body);
  },
  createOrder(body) {
    return requests.post('/api/orders/index', body);
  },
  saveOrder(body) {
    return requests.post('/api/orders/draftorder', body);
  },
  getsaveOrder(id,body){
    return requests.get(`/api/orders/getdraftorder?id=${id}`, body);
  },
  updateSaveOrder(id, body){
    return requests.put(`api/orders/updateorder?id=${id}`, body);
  },

  getOrderByUser(id, body) {
    return requests.get(`/api/orders/user/${id}`, body);
  },

  getOrderById(id) {
    return requests.get(`/api/orders/get?id=${id}`);
  },

  updateOrder(id, body, headers) {
    return requests.put(`/api/orders/${id}`, body, headers);
  },

  deleteOrder(id) {
    return requests.delete(`/api/orders/${id}`);
  },

  searchCustomers(str) {
    return requests.delete(`/api/customers?q=${str}`);
  },
};

export default OrderServices;
