import requests from './httpService';

const ReportServices = {

  getDashboard(body) {
    return requests.post('/api/dashboard', body);
  },
};

export default ReportServices;
