import { React, useState, useEffect, useMemo } from 'react'
import styled from "styled-components";
import DataTable from '../components/DataTable'
import OrderServices from '../services/OrderServices';
import { notifySuccess, notifyError } from '../utils/toast';


/* React table */
import { useTable, useSortBy, usePagination } from "react-table";
/* End react table */

const Styles = styled.div``;

function Mtable() {

  const columns = useMemo(
    () => [
    
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "First name",
          accessor: "billing.first_name",
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Profile Progress",
          accessor: "progress",
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Data",
          accessor: "data",
        }
  ]);


  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [showData, setShowData] = useState([])
  const [pageLoaded, setPageLoaded] = useState(false)
  const [totalRecords, setTotalrecords] = useState(0)
  var originalData = [];

  function fetchData(i) {

    var tData = [];
    setLoading(true)

      OrderServices.getAllOrders()
      .then((response) => {
        originalData = response
        setData(response)
        setLoading(false)
        setPageLoaded(true)
        setPageCount(Math.round(originalData.length / i.pageSize))
        setTotalrecords(originalData.length)        
        setShowData(originalData)
        })
        .catch((err) => {
            notifyError(err ? err.response.data.message : err.message);
            setLoading(false);
        });
    
  }
  
  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-lg-12">
                <h4 className="card-title">Doctors Test</h4>
              </div>

              <div className="col-lg-12">
                  <DataTable
                    columns={columns}
                    data={showData}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount} 
                    pageRecords = {totalRecords}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Mtable